/**
 * @file Notifications page component
 * @author Alwyn Tan
 */

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { loadNotifications } from '../../../actions/notifications'
import AppLayout from '../../templates/AppLayout'
import LoadingCard from '../../compound/Cards/LoadingCard'
import NotificationCard from '../../compound/Cards/NotificationCard'
import InfiniteList from '../../compound/InfiniteList'

const Container = styled.div`
  align-self: center;
  justify-self: flex-start;
  width: 100%;
  height: 100%;

  > div {
    height: 100%;
    min-height: 100%;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  > * {
    max-width: 400px;
    width: 100%;
  }
`

const Notifications = () => {
  const dispatch = useDispatch()
  const notifications = useSelector(state => state.notifications)

  return (
    <AppLayout>
      <Container>
        <InfiniteList
          items={notifications.ids}
          loadMoreItems={() => dispatch(loadNotifications())}
          canLoadMore={notifications.canLoadMore}
          loading={notifications.loading}
          stickyHeader={
            <Header>
              <h2>Notifications</h2>
            </Header>
          }
          loadingComponent={<LoadingCard style={{ maxWidth: 400 }} />}
          endOfListComponent={
            <p style={{ padding: '20px 0', maxWidth: 400 }}>
              You’ve reached the end of the list 👾
            </p>
          }
          noItemsComponent={
            <p style={{ paddingTop: 20, maxWidth: 400 }}>
              No Notifications to Show
            </p>
          }
        >
          {item => <NotificationCard id={item} style={{ maxWidth: 400 }} />}
        </InfiniteList>
      </Container>
    </AppLayout>
  )
}

export default Notifications
