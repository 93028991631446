/**
 * @file Autofill input
 * @author Alwyn Tan
 */

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import GoogleService from '../../services/google'

const Container = styled.div``

const MapPreview = styled.div`
  height: 250px;
  border-radius: 10px;
  margin-top: 10px;
  opacity: ${({ hasValue }) => (hasValue ? 1 : 0.5)};
`

const StyledSelect = styled(AsyncSelect)`
  * {
    color: ${({ theme }) => theme.Text} !important;
  }

  .react-select__placeholder {
    opacity: 0.2;
  }

  .react-select__control {
    height: 40px;
    background-color: ${({ theme }) => theme.Tertiary};
    border: 0;
    border-radius: 10px;
    font-weight: 500;
    font-size: 0.933em;
  }

  .react-select__value-container {
    height: 100%;
    padding-left: 12px;
  }

  .react-select__indicators {
    display: none;
  }

  .react-select__input {
    height: 100%;

    > input {
      margin-bottom: 0;
    }
  }

  .react-select__menu {
    margin-top: 5;
    background-color: ${({ theme }) => theme.Tertiary};

    .react-select__option--is-focused,
    .react-select__option:focus,
    .react-select__option:hover,
    .react-select__option:active {
      background-color: ${({ theme }) => theme.Secondary};
    }
  }
`

const AutofillAddressInput = ({ placeholder, onChange, value }) => {
  const [map, setMap] = useState(null)
  const mapMarkersRef = useRef([])
  const mapComponentRef = useRef(null) // ref of the component
  const [sessionToken, setSessionToken] = useState(null)

  const loadOptions = async (input, callback) => {
    if (!sessionToken)
      setSessionToken(await GoogleService.getAutocompleteSessionToken())
    const response = await GoogleService.getPlacePredictions({
      input,
      sessionToken,
    })
    const options = response.predictions.map(prediction => ({
      value: prediction,
      label: prediction.description,
    }))

    callback(options)
  }

  useEffect(() => {
    const loadMap = async () => {
      setMap(
        await GoogleService.initializeMap(mapComponentRef.current, {
          center: { lat: 37.7854, lng: -122.4294 },
          zoom: 12,
          disableDefaultUI: true,
        })
      )
    }

    loadMap()
  }, [])

  const transform = {
    output: async v => {
      const place = await GoogleService.getPlaceDetails(map, {
        placeId: v.place_id,
        sessionToken,
      })
      setSessionToken(null)

      for (const marker of mapMarkersRef.current) marker.setMap(null)
      mapMarkersRef.current = []
      if (place.geometry) {
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport)
        } else {
          map.setCenter(place.geometry.location)
          map.setZoom(14)
        }

        mapMarkersRef.current.push(
          GoogleService.createMarker({ map, position: place.geometry.location })
        )
      }

      const address = GoogleService.parseAddressComponents(
        place.address_components
      )

      const picture =
        place?.photos &&
        place.photos[0].getUrl({ maxHeight: 225, maxWidth: 335 })

      return {
        name: place.name,
        ...address,
        placeID: place.place_id,
        picture,
        utcOffsetMinutes: place.utc_offset_minutes,
      }
    },
  }

  return (
    <Container>
      <StyledSelect
        cacheOptions
        classNamePrefix="react-select"
        loadOptions={loadOptions}
        placeholder={placeholder}
        onChange={async v => onChange(await transform.output(v.value))}
        value={value ? { value, label: value?.name } : ''}
      />
      <MapPreview ref={mapComponentRef} hasValue={value} />
    </Container>
  )
}

AutofillAddressInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

AutofillAddressInput.defaultProps = {
  placeholder: 'location',
  value: null,
}

export default AutofillAddressInput
