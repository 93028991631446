/**
 * @file Event Details Footer
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { AnimatePresence } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import {
  registerEvent,
  registerEventWaitlist,
  unregisterEventWaitlist,
} from '../../../../actions/events'
import { setAuthSuccessCallback } from '../../../../actions/auth'
import { checkoutEvent } from '../../../../actions/stripe'
import BringAFriendModal from '../BringAFriendModal'
import Button from '../../../simple/Button'
import AuthOverlay from '../../Auth/AuthOverlay'

const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const EventCTA = ({ event }) => {
  const [showAuth, setShowAuth] = useState(false)
  const [showBringAFriendModal, setShowBringAFriendModal] = useState(false)
  const dispatch = useDispatch()
  const accessToken = useSelector(state => state.auth.accessToken)

  const handleRegister = () => {
    // handles registering for the event

    if (event.price) {
      if (event.seatsLeft === 1) {
        dispatch(checkoutEvent({ eventID: event.id }))
      } else {
        // register with paying the event, show the friend modal etc
        setShowBringAFriendModal(true)
      }
    } else {
      dispatch(registerEvent({ eventID: event.id }))
    }
  }

  const handleButtonClick = () => {
    const numbers = [...event.participants, { phoneNumber: '+17144727223' }]
      .map(p => p.phoneNumber)
      .join(',')

    if (event.isHost) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/android/i.test(userAgent)) {
        window.open(`sms:${numbers}`)
      } else {
        window.open(`sms:/open?addresses=${numbers}`)
      }
    } else if (!event.userRegistered) {
      let action
      if (event.userOffWaitlist) {
        action = handleRegister
      } else if (event.userOnWaitlist) {
        if (window.confirm('Are you sure you want to leave the waitlist?')) {
          action = () => dispatch(unregisterEventWaitlist(event.id))
        } else {
          return
        }
      } else if (event.seatsLeft <= 0) {
        action = () => dispatch(registerEventWaitlist(event.id))
      } else {
        action = handleRegister
      }

      if (!accessToken) {
        setShowAuth(true)
        dispatch(setAuthSuccessCallback(action))
      } else {
        setShowAuth(false)
        action()
      }
    } else if (event.link) {
      window.open(event.link, '_blank')
    } else if (event.location) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          event.location
        )}`,
        '_blank'
      )
    }
  }

  const showButton = () => {
    if (
      event.loading ||
      (event.userRegistered &&
        dayjs(event.startDate).diff(dayjs(), 'minute') > 10) ||
      dayjs(event.endDate).isBefore(dayjs())
    )
      return false
    return true
  }

  const renderButtonText = () => {
    if (event.isHost) return 'Create SMS Group Chat'
    if (event.userRegistered) return 'Join Event'
    if (event.userOffWaitlist) return 'Reserve a Spot'
    if (event.userOnWaitlist) return 'Leave the Waitlist'
    return event.seatsLeft <= 0 ? 'Join the Waitlist' : 'Reserve a Spot'
  }

  return (
    <Container>
      {showButton() && (
        <Button onClick={handleButtonClick}>{renderButtonText()}</Button>
      )}
      <AnimatePresence>
        {showAuth && !accessToken && <AuthOverlay />}
        {showBringAFriendModal && (
          <BringAFriendModal
            onYes={() =>
              dispatch(checkoutEvent({ eventID: event.id, extraSeats: 1 }))
            }
            onNo={() => dispatch(checkoutEvent({ eventID: event.id }))}
            onCancel={() => setShowBringAFriendModal(false)}
          />
        )}
      </AnimatePresence>
    </Container>
  )
}

EventCTA.propTypes = {
  /* eslint-disable-next-line react/forbid-prop-types */
  event: PropTypes.object.isRequired,
}

export default EventCTA
