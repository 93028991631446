/**
 * @file Create Event page 2
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Checkbox from '../../../simple/Checkbox'

const Page4 = ({ register }) => {
  const currentFormData = useSelector(
    state => state.events.createEventStore.formData
  )

  return (
    <>
      <h3 style={{ marginBottom: 20 }}>Extra Settings</h3>
      <Checkbox
        ref={register}
        name="settings.showGuestList"
        defaultChecked={currentFormData.settings.showGuestList}
      >
        Show guest list when users sign up
      </Checkbox>
      <Checkbox
        ref={register}
        name="settings.showFunFacts"
        defaultChecked={currentFormData.settings.showFunFacts}
      >
        Show guests’ fun facts
      </Checkbox>
      <Checkbox
        ref={register}
        name="settings.showProfessions"
        defaultChecked={currentFormData.settings.showProfessions}
      >
        Show guests’ professions
      </Checkbox>
      <Checkbox
        ref={register}
        name="settings.showQuestion"
        defaultChecked={currentFormData.settings.showQuestion}
      >
        Show guests’ answers to Question of the Event (if applicable)
      </Checkbox>
    </>
  )
}

Page4.propTypes = {
  register: PropTypes.func.isRequired,
}

export default Page4
