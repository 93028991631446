/* eslint-disable react/jsx-props-no-spreading */

/**
 * @file Custom Checkbox Component
 * @author Alwyn Tan
 */

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.label`
  width: 100%;
  max-width: 315px;
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: 28px;
  user-select: none;
  border-radius: 2px;
  opacity: ${props => (props.disabled ? 0.4 : 1)};

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  // show the checkmark when checked
  input:checked ~ span:after {
    display: block;
  }

  input:checked ~ span {
    background-color: #2196f3;
  }
`

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: ${({ theme }) => theme.Tertiary};
  border-radius: 2px;

  /* Style the checkmark/indicator */
  :after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: '';
    position: absolute;
    display: none;
  }
`

const Checkbox = forwardRef(({ children, ...props }, ref) => {
  return (
    <Container disabled={props.disabled}>
      {children}
      <input type="checkbox" ref={ref} {...props} />
      <Checkmark />
    </Container>
  )
})

Checkbox.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

Checkbox.defaultProps = {
  disabled: false,
}

export default Checkbox
