/**
 * @file Notification Card
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import PlainCard from './PlainCard'
import placeholderImage from '../../../images/profilePlaceholder.png'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Pic = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
  margin-right: 20px;
`

const NotificationCard = ({ id, style }) => {
  const notification = useSelector(state => state.notifications.details[id])

  const handleNotificationClick = () => {
    if (notification.url) navigate(notification.url)
  }

  return (
    <PlainCard
      style={{ cursor: notification.url ? 'pointer' : 'default', ...style }}
      onClick={handleNotificationClick}
    >
      <Container>
        <Pic src={notification.picture || placeholderImage} />
        <p>
          {notification.message}{' '}
          <span style={{ opacity: 0.4 }}>
            {dayjs(notification.createdAt).fromNow(true)}
          </span>
        </p>
      </Container>
    </PlainCard>
  )
}

NotificationCard.propTypes = {
  id: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
}

NotificationCard.defaultProps = { style: {} }

export default NotificationCard
