/**
 * @file Verification Container
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import AppLayout from '../../templates/AppLayout'

const CenterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Verification = ({ children }) => {
  return (
    <AppLayout hideHeaderAndPadding>
      <CenterContainer>{children}</CenterContainer>
    </AppLayout>
  )
}

Verification.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Verification
