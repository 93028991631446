/**
 * @file Create Event page 1
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Controller } from 'react-hook-form'
import { PureInput } from '../../../simple/Input'
import AutofillAddressInput from '../../../compound/AutofillAddressInput'

const Page1 = ({ register, control }) => {
  const currentFormData = useSelector(
    state => state.events.createEventStore.formData
  )

  return (
    <>
      <h3>Max Participants</h3>
      <PureInput
        key="maxParticipants"
        name="maxParticipants"
        placeholder="Max Participants"
        type="number"
        ref={register({
          required: true,
          validate: value => !Number.isNaN(value),
        })}
        defaultValue={currentFormData.maxParticipants || ''}
      />
      <h3>Location</h3>
      <Controller
        as={AutofillAddressInput}
        name="locationV2"
        key="location"
        placeholder="location"
        defaultValue={currentFormData.locationV2}
        control={control}
        rules={{ required: true }}
      />
    </>
  )
}

Page1.propTypes = {
  control: PropTypes.oneOfType([PropTypes.object]).isRequired,
  register: PropTypes.func.isRequired,
}

export default Page1
