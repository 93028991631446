/**
 * @file Page for existing users to choose a username if they dont have one yet
 * @author Alwyn Tan
 */

import { motion } from 'framer-motion'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import {
  setLoadingUser,
  setUpdateUsernameFailure,
  setUpdateUsernameSuccess,
  updateUsername,
  validateUsername,
} from '../../../actions/user'
import AppLayout from '../../templates/AppLayout'
import Button from '../../simple/Button'
import { PureInput } from '../../simple/Input'

const Form = styled(motion.form)`
  align-self: center;
  justify-self: flex-start;
  width: 100%;
  max-width: 375px;
  flex-direction: column;
  padding: 60px 30px;
  position: relative;
  display: table;
  min-height: 100%;
  height: 100%;

  > h2 {
    padding-bottom: 5px;
  }

  > h3 {
    padding-bottom: 15px;
  }
`

const ErrorText = styled.h4`
  color: #d63031;
  padding-top: 5px;
  white-space: pre-wrap;
  padding-left: 20px;
`

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 20px;

  > * {
    width: 100%;
  }
`

const ChooseUsernameOverlay = () => {
  const dispatch = useDispatch()
  const updateUsernameFailure = useSelector(
    state => state.user.updateUsernameFailure
  )
  const availableUsernames = useSelector(state => state.user.availableUsernames)
  const user = useSelector(state => state.auth.user)
  const loading = useSelector(state => state.user.loading)
  const {
    register,
    formState,
    errors,
    setError,
    getValues,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  })

  const dispatchValidateUsername = useCallback(
    debounce(username => {
      dispatch(validateUsername(username))
    }, 250),
    []
  )

  useEffect(() => {
    return () => {
      dispatch(setUpdateUsernameFailure(''))
      dispatch(setUpdateUsernameSuccess(false))
    }
  }, [])

  useEffect(() => {
    const username = getValues('username')
    if (username && !availableUsernames[username]) {
      setError('username', { message: 'Username not available' })
    }
  }, [availableUsernames])

  const onSubmit = data => {
    dispatch(updateUsername(data.username))
  }

  const handleInputChange = e => {
    const username = e.target.value

    // dont dispatch if there is a pattern error
    if (!username || Object.values(errors).some(err => err.type === 'pattern'))
      return

    dispatch(setLoadingUser(true))
    dispatchValidateUsername(username)
  }

  return (
    <AppLayout hideHeaderAndPadding>
      <Form
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2>Hi, {user.name}</h2>
        <h3>Please choose a username</h3>
        <PureInput
          onChange={e => handleInputChange(e)}
          name="username"
          placeholder="soloclub"
          defaultValue=""
          ref={register({
            required: true,
            pattern: {
              value: /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
              message: 'Please enter a valid username',
            },
          })}
        />
        {updateUsernameFailure && (
          <ErrorText>{updateUsernameFailure}</ErrorText>
        )}
        {errors.username?.message && (
          <ErrorText>{errors.username.message}</ErrorText>
        )}
        <ButtonContainer>
          <Button disabled={!formState.isValid || loading}>Confirm</Button>
        </ButtonContainer>
      </Form>
    </AppLayout>
  )
}

export default ChooseUsernameOverlay
