/**
 * @file Custom Inverted Chat Infinite List
 * @author Alwyn Tan
 */

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import ChatMessage from './ChatMessage'
import { loadChatMessages } from '../../../actions/chat'

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: flex;
`

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: auto;
`

const LOAD_THRESHOLD = 15

const ChatMessageList = ({ roomID }) => {
  const dispatch = useDispatch()
  const isScrollingRef = useRef(false)
  const chatRef = useRef(null)
  const messages = useSelector(state => state.chat.messages[roomID]) || {
    loading: false,
    canLoadMore: true,
    ids: [],
  }

  const scrollMessages = () => {
    if (chatRef.current)
      chatRef.current.scrollTop = chatRef.current.scrollHeight
  }

  useEffect(() => {
    if (
      messages.ids.length === 0 ||
      chatRef?.current?.scrollTop < LOAD_THRESHOLD
    )
      dispatch(loadChatMessages({ roomID }))
  }, [messages.ids.length, dispatch, roomID, chatRef?.current?.scrollTop])

  useEffect(() => scrollMessages(), [])

  // todo: mini optimization, we don't want this to fire when is scrolling is changing actually.....
  useEffect(() => {
    if (!isScrollingRef.current) {
      scrollMessages()
    }
  }, [messages.ids.length])

  const handleScroll = e => {
    if (
      chatRef.current.scrollTop + chatRef.current.clientHeight ===
      chatRef.current.scrollHeight
    ) {
      if (isScrollingRef.current) isScrollingRef.current = false
    } else if (!isScrollingRef.current) isScrollingRef.current = true

    if (e.target.scrollTop < LOAD_THRESHOLD && !messages.loading) {
      dispatch(loadChatMessages({ roomID }))
    }
  }

  const renderChatMessages = () => {
    return messages.ids
      .map((id, index) => {
        // flip the prev/next because they're being reversed
        const nextID = index !== 0 ? messages.ids[index - 1] : null
        const prevID =
          messages.ids.length > index + 1 ? messages.ids[index + 1] : null

        return <ChatMessage id={id} prevID={prevID} nextID={nextID} key={id} />
      })
      .reverse()
  }

  return (
    <Container ref={chatRef} onScroll={handleScroll}>
      <Wrapper>{renderChatMessages()}</Wrapper>
    </Container>
  )
}

ChatMessageList.propTypes = { roomID: PropTypes.string.isRequired }

export default ChatMessageList
