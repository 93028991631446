/**
 * @file The "webapp" part of the website (handles all the client side routing for auth purposes)
 * @author Alwyn Tan
 */

import React from 'react'
import { Router, Redirect } from '@reach/router'
import { useSelector } from 'react-redux'
import Events from '../components/pages/Events'
import Chat from '../components/pages/Chat'
import EditProfile from '../components/pages/User/EditProfile'
import Profile from '../components/pages/Profile'
import App404 from '../components/pages/App404'
import UpcomingEvents from '../components/pages/Events/UpcomingEvents'
import PastEvents from '../components/pages/Events/PastEvents'
import User from '../components/pages/User'
import EventInvite from '../components/pages/Events/EventInvite'
import EventDetails from '../components/pages/Events/EventDetails'
import ProtectedRoute from '../components/simple/ProtectedRoute'
import OnboardingOverlay from '../components/pages/Onboarding/OnboardingOverlay'
import MatchesReview from '../components/pages/Events/MatchesReview'
import CreateEvent from '../components/pages/Events/CreateEvent'
import Settings from '../components/pages/Settings'
import Notifications from '../components/pages/Notifications'
import Verification from '../components/pages/Verification'
import EmailVerification from '../components/pages/Verification/EmailVerification'
import Pod from '../components/pages/Pod'
import PodInfo from '../components/pages/Pod/PodInfo'
import AuthOverlay from '../components/pages/Auth/AuthOverlay'
import ChooseUsernameOverlay from '../components/pages/Onboarding/ChooseUsernameOverlay'
import ExploreEvents from '../components/pages/Explore/ExploreEvents'
import PodMembers from '../components/pages/Pod/PodMembers'

const App = () => {
  const accessTokenLoaded = useSelector(state => state.auth.accessTokenLoaded)
  const hasAccessToken = useSelector(state => !!state.auth.accessToken)
  const currentUser = useSelector(state => state.auth.user)

  // wait for loading to complete
  if (!accessTokenLoaded) return null
  if (hasAccessToken && currentUser?.newUser) return <OnboardingOverlay />
  if (hasAccessToken && !currentUser?.username) return <ChooseUsernameOverlay />

  return (
    <>
      <Router basepath="/app" primary={false}>
        <ExploreEvents path="/" />

        {hasAccessToken ? (
          <Redirect from="/auth" to="/app/" noThrow replace />
        ) : (
          <AuthOverlay path="/auth" key="auth" />
        )}

        <Pod path="pod">
          <PodInfo path=":name" />
          <PodMembers path=":name/members" />
        </Pod>

        <Events path="events">
          <Redirect from="/" to="past" noThrow />
          <ProtectedRoute path="past" component={PastEvents} />
          <ProtectedRoute path="upcoming" component={UpcomingEvents} />
          <ProtectedRoute path="matches/:id" component={MatchesReview} />
          <ProtectedRoute path="create" component={CreateEvent} />
          <ProtectedRoute path="update/:id" component={CreateEvent} />
          <EventInvite path="invite/:id" />
          <EventDetails path=":id" />
        </Events>

        <ProtectedRoute path="/notifications" component={Notifications} />

        <ProtectedRoute path="/settings" component={Settings} />

        <ProtectedRoute path="/profile" component={Profile} />
        <ProtectedRoute path="/profile/edit" component={EditProfile} />

        <ProtectedRoute path="/chat/*roomID" component={Chat} />

        <User path="/user/:id" />

        <Redirect from="/e/:id" to="/app/events/:id" noThrow />

        <Verification path="verification">
          <EmailVerification path="/email" />
        </Verification>

        <App404 default />
      </Router>
    </>
  )
}

export default App
