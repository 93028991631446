/**
 * @file Create Event page 2
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import TextArea from '../../../simple/TextArea'
import { PureInput } from '../../../simple/Input'

const Page2 = ({ register }) => {
  const currentFormData = useSelector(
    state => state.events.createEventStore.formData
  )

  return (
    <>
      <h3>
        Description <span style={{ opacity: 0.25 }}>(Optional)</span>
      </h3>
      <TextArea
        name="about"
        placeholder="Event Description"
        ref={register}
        defaultValue={currentFormData.about || ''}
      />
      <h3>
        Question of the Event <span style={{ opacity: 0.25 }}>(Optional)</span>
      </h3>
      <PureInput
        name="additionalInfoQuestion"
        placeholder="Question"
        ref={register}
        defaultValue={currentFormData.additionalInfoQuestion || ''}
      />
    </>
  )
}

Page2.propTypes = {
  register: PropTypes.func.isRequired,
}

export default Page2
