/**
 * @file Individual Chat Message
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import placeholderImage from '../../../images/profilePlaceholder.png'

const Container = styled.div`
  display: flex;
  margin-left: ${props => (props.isOther ? 'unset' : 'auto')};
  margin-right: ${props => (props.isOther ? 'auto' : 'unset')};
  justify-self: flex-start;
  max-width: 60%;
  padding: 0 20px;
`

const MiniProfileImage = styled.img`
  min-height: 20px;
  min-width: 20px;
  height: 20px;
  width: 20px;
  margin-right: 5px;
  border-radius: 10px;
  object-fit: cover;
  margin-top: auto;
  margin-bottom: 0px;
`

const Message = styled.div`
  font-weight: 400;
  border-radius: 20px;
  padding: 10px 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: ${({ isOther }) => (isOther ? 12 : 15)}px;
  padding-right: ${({ isOther }) => (!isOther ? 12 : 15)}px;
  max-width: 100%;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.Text};
  background-color: ${({ isOther, theme }) =>
    isOther ? 'transparent' : theme.Tertiary};
  border-width: ${({ isOther }) => (isOther ? '1px' : '0')};
  overflow-wrap: break-word;
`

const ChatMessage = ({ prevID, id, nextID }) => {
  const prevMessage = useSelector(state => state.chat.messageDetails[prevID])
  const message = useSelector(state => state.chat.messageDetails[id])
  const nextMessage = useSelector(state => state.chat.messageDetails[nextID])

  const currentUserID = useSelector(state => state.auth.user?.id)

  if (!message) return null

  const isOther = message.from.id !== currentUserID

  return (
    <Container
      isOther={isOther}
      style={{
        marginTop: prevMessage?.from?.id === message.from.id ? 2.5 : 5,
        marginBottom: nextMessage?.from?.id === message.from.id ? 2.5 : 5,
      }}
    >
      {isOther && (
        <MiniProfileImage
          src={message.from.picture || placeholderImage}
          style={{
            opacity: nextMessage?.from?.id === message.from.id ? 0 : 1,
          }}
          onClick={() => navigate(`/app/user/${message.from.id}`)}
        />
      )}
      <Message
        isOther={isOther}
        style={{
          borderTopLeftRadius:
            isOther && prevMessage?.from?.id === message.from.id ? 7 : 20,
          borderBottomLeftRadius:
            isOther && nextMessage?.from?.id === message.from.id ? 7 : 20,
          borderTopRightRadius:
            !isOther && prevMessage?.from?.id === message.from.id ? 7 : 20,
          borderBottomRightRadius:
            !isOther && nextMessage?.from?.id === message.from.id ? 7 : 20,
        }}
      >
        {message.value}
      </Message>
    </Container>
  )
}

ChatMessage.propTypes = {
  id: PropTypes.string.isRequired,
  prevID: PropTypes.string,
  nextID: PropTypes.string,
}

ChatMessage.defaultProps = {
  prevID: null,
  nextID: null,
}

export default ChatMessage
