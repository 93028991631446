/**
 * @file Renders a pod member
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import placeholderImage from '../../../images/profilePlaceholder.png'

const Container = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: center;
  width: 100%;
  cursor: pointer;

  img {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin-right: 15px;
    object-fit: cover;
  }
`

const PodMember = ({ id }) => {
  const user = useSelector(state => state.user.details[id])
  return (
    <Container onClick={() => navigate(`/app/user/${user.id}`)}>
      <img src={user.picture || placeholderImage} alt={user.name} />
      <div>
        <h3>{user.name}</h3>
        <p>{user.profession}</p>
      </div>
    </Container>
  )
}

PodMember.propTypes = {
  id: PropTypes.string.isRequired,
}

export default PodMember
