/**
 * @file A simple loading card component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import PlainCard from './PlainCard'

const Pulse = styled(motion.div)`
  opacity: ${({ theme }) => (theme.isDarkMode ? 0.5 : 1)};
  background-color: ${({ theme }) => theme.Primary};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
`

const LoadingCard = ({ style }) => {
  return (
    <PlainCard style={{ ...style, position: 'relative', height: 100 }}>
      <Pulse
        animate={{ opacity: 0 }}
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          duration: 0.8,
          ease: 'easeInOut',
        }}
      />
    </PlainCard>
  )
}

LoadingCard.propTypes = { style: PropTypes.oneOfType([PropTypes.object]) }

LoadingCard.defaultProps = { style: {} }

export default LoadingCard
