/**
 * @file event details screen
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { AnimatePresence } from 'framer-motion'
import toast from 'react-hot-toast'
import { Edit2, MessageCircle, Share } from 'react-feather'
import placeholderImage from '../../../../images/profilePlaceholder.png'
import useQueryString from '../../../../hooks/useQueryString'
import { unregisterEvent, getEventDetails } from '../../../../actions/events'
import EventCardList from './EventCardList'
import EventCTA from './EventCTA'
import EventTag from './EventTag'
import EventParticipantList from './EventParticipantList'
import EventLoading from './EventLoading'
import SEO from '../../../simple/SEO'
import AdditionalInfoModal from '../AdditionalInfoModal'
import EventDropdownButton from './EventDropdownButton'
import AddToCalendar from '../../../simple/AddToCalendar'
import Button from '../../../simple/Button'

const TitleImage = styled.img`
  width: 100%;
  height: 215px;
  background-color: ${({ theme }) => theme.Secondary};
  object-fit: cover;
  border-radius: 5px;
`

const Container = styled.div`
  padding-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 90px;
`

const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.Secondary};
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0, 0, 0, 0.15)'};
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
`

const ExtraSeatsContainer = styled.div`
  background-color: ${({ theme }) => theme.Secondary};
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0, 0, 0, 0.15)'};
  border-radius: 10px;
  padding: 30px 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
`

const Content = styled.div`
  padding: 20px 20px 100px 20px;
  p {
    opacity: 0.8;
  }
`

const EventInfoItem = styled.div`
  padding-top: 10px;
  display: flex;

  > span {
    font-size: 22px;
    padding-right: 10px;
  }
`

const EventButtonRow = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
`

const EventIconButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > span {
    font-size: 22px;
    text-align: center;
  }
`

const EventSection = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid
    ${({ theme }) => (theme.isDarkMode ? '#ffffff' : '#000000')}26;

  h3 {
    padding-bottom: 5px;
  }

  p {
    white-space: pre-wrap;
  }
`

const HostedContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const HostPicture = styled.img`
  object-fit: cover;
  border-radius: 28px;
  width: 56px;
  height: 56px;
`

const LocationLink = styled.a`
  outline: none;
  text-decoration: none;
  color: inherit;
  overflow: hidden;

  > h4 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const SponsorPicture = styled.img`
  margin-top: 5px;
  object-fit: contain;
  width: 60%;
`

const EventDetails = ({ id }) => {
  const dispatch = useDispatch()
  const event = useSelector(state => state.events.details[id])
  const currentUser = useSelector(state => state.auth.user)
  const queryString = useQueryString()
  const theme = useTheme()

  // ask for event details? on every refresh?
  useEffect(() => {
    dispatch(getEventDetails({ id }))
  }, [])

  useEffect(() => {
    if (!event) dispatch(getEventDetails({ id }))
  }, [event, dispatch, id])

  useEffect(() => {
    if (event?.notFound) navigate('/app/404', { replace: true })
  }, [event])

  useEffect(() => {
    if (queryString.success) {
      toast.success('Registered')
      setTimeout(() => {
        navigate(`/app/events/${id}`)
      }, 2000)
    }
  }, [queryString.success])

  // todo: put loading screen instead of null
  if (!event || event?.notFound) return null

  if (event.loading) return <EventLoading />

  const shouldShowModal = () => {
    return (
      currentUser?.id &&
      event.userRegistered &&
      event.additionalInfoQuestion &&
      !event.additionalInfo?.[currentUser?.id]
    )
  }

  const handleShareEvent = () => {
    if (navigator?.share) {
      navigator.share({ url: window.location.href })
    } else {
      navigator.clipboard.writeText(window.location.href)
      toast.success('Link Copied!')
    }
  }

  const handleCancelClick = () => {
    if (event.price) {
      // send an email to cancel instead
      const subject = `Re: Cancellation for ${event.title}`
      const body = `Dear Solo Team,\n
        I can no longer make it to ${event.title} and would like to cancel my reservation:\n
        My User ID: ${currentUser.id}
        My Name: ${currentUser.name}
        Event ID: ${event.title}
        Event Title: ${event.title}
      `

      window.open(
        `mailto:info@soloclub.app?subject=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(body)}`
      )
    } else if (event.userRegistered && window.confirm('Are you sure?')) {
      dispatch(unregisterEvent(event.id))
    }
  }

  const handleShareExtraSeatsBought = code => {
    const extraSeatInviteLink = `${
      window.location.origin
    }/app/events/invite/${id}?inv=${encodeURIComponent(code)}`

    if (navigator?.share) {
      navigator.share({ url: extraSeatInviteLink })
    } else {
      navigator.clipboard.writeText(extraSeatInviteLink)
      toast.success('Link Copied!')
    }
  }

  const renderExtraSeatsBought = () =>
    event.extraSeats.map(curr => (
      <ExtraSeatsContainer key={curr.code}>
        {curr.status === 'ACTIVE' ? (
          <>
            <h3 style={{ paddingBottom: 10 }}>
              You have an unclaimed paid ticket
            </h3>
            <Button
              size="small"
              onClick={() => handleShareExtraSeatsBought(curr.code)}
            >
              Invite a Friend
            </Button>
          </>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              style={{
                height: 20,
                width: 20,
                borderRadius: 10,
                marginRight: 10,
              }}
              alt={curr.redeemedBy.name}
              src={curr.redeemedBy.picture || placeholderImage}
            />
            <p>
              <span style={{ fontWeight: 600 }}>{curr.redeemedBy.name}</span>{' '}
              redeemed your invite
            </p>
          </div>
        )}
      </ExtraSeatsContainer>
    ))

  return (
    <Container>
      <AnimatePresence>
        {shouldShowModal() && <AdditionalInfoModal eventID={id} />}
      </AnimatePresence>
      <SEO
        title={event.title}
        description={event.about}
        image={event.picture}
      />
      {renderExtraSeatsBought()}
      <ContentContainer>
        <TitleImage src={event.picture} />
        {dayjs().isAfter(dayjs(event.endDate)) &&
        !event.userRegistered &&
        !event.isHost ? (
          <Content>
            <h2>{event.title}</h2>
            <h3 style={{ paddingTop: 10 }}>Event is Over</h3>
          </Content>
        ) : (
          <Content>
            {event.type === 'online' && <EventTag tag="online" />}
            <h2>{event.title}</h2>
            {(event?.locationV2?.formatted || event.location) && (
              <EventInfoItem>
                <span className="icon-map-pin" style={{ fontSize: 22 }} />
                <LocationLink
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    event?.locationV2?.formatted || event.location
                  )}`}
                >
                  <h4>{event?.locationV2?.formatted || event.location}</h4>
                </LocationLink>
              </EventInfoItem>
            )}
            {event.startDate && (
              <EventInfoItem>
                <span className="icon-calendar" />
                <AddToCalendar
                  event={{
                    description: event.about,
                    location:
                      event.type === 'online'
                        ? event.link
                        : event.locationV2?.formatted || event.location,
                    startDate: event.startDate,
                    endDate: event.endDate,
                    title: event.title,
                  }}
                  labelText={dayjs(event.startDate).format('D MMMM YYYY')}
                />
              </EventInfoItem>
            )}
            {event.startDate && event.endDate && (
              <EventInfoItem>
                <span className="icon-clock" />
                <h4>
                  {dayjs(event.startDate).format('h:mma')} -{' '}
                  {dayjs(event.endDate).format('h:mma')}
                </h4>
              </EventInfoItem>
            )}
            {event.participants && (
              <EventInfoItem>
                <span className="icon-user" />
                {event.seatsLeft === 0 ? (
                  <h4>Sold Out</h4>
                ) : (
                  <h4>
                    {event.seatsLeft} seat{event.seatsLeft === 1 ? ' ' : 's '}
                    remaining
                  </h4>
                )}
              </EventInfoItem>
            )}
            {event.price && (
              <EventInfoItem>
                <span className="icon-tag" />
                <h4>{`$${event.price / 100}`}</h4>
              </EventInfoItem>
            )}
            {(event.userRegistered || event.isHost) && (
              <EventButtonRow>
                <EventIconButton
                  onClick={() => {
                    navigate(`/app/chat/${event.chatRoom}`)
                  }}
                >
                  <MessageCircle size={22} />
                  <h5>Message</h5>
                </EventIconButton>
                <EventIconButton onClick={handleShareEvent}>
                  <Share size={22} />
                  <h5>Share</h5>
                </EventIconButton>
                {dayjs().isBefore(dayjs(event.startDate)) &&
                  (event.isHost ? (
                    <EventDropdownButton eventID={event.id} />
                  ) : (
                    <EventIconButton onClick={handleCancelClick}>
                      <Edit2 size={22} />
                      <h5>Cancel</h5>
                    </EventIconButton>
                  ))}
              </EventButtonRow>
            )}
            {event.about && (
              <EventSection>
                <h3>About</h3>
                <p>{event.about}</p>
              </EventSection>
            )}
            {(event.userRegistered || event.isHost) && (
              <>
                {event.settings.showGuestList && (
                  <EventSection>
                    <h3>Who's Coming</h3>
                    <EventParticipantList participants={event.participants} />
                  </EventSection>
                )}
                {event.additionalInfoQuestion && event.settings.showQuestion && (
                  <EventSection>
                    <h3>{event.additionalInfoQuestion}</h3>
                    <EventCardList additionalInfo={event.additionalInfo} />
                  </EventSection>
                )}
                {event.settings.showFunFacts && (
                  <EventSection>
                    <h3>Things About Us</h3>
                    <EventCardList
                      participants={event.participants}
                      type="funFact"
                    />
                  </EventSection>
                )}
                {event.settings.showProfessions && (
                  <EventSection>
                    <h3>What We do</h3>
                    <EventCardList
                      participants={event.participants}
                      type="profession"
                    />
                  </EventSection>
                )}
              </>
            )}
            {event.host && (
              <EventSection>
                <HostedContainer>
                  <div>
                    <h3>Hosted by {event.host.name}</h3>
                    <p>Joined {dayjs(event.host.joined).format('MMMM YYYY')}</p>
                  </div>
                  <HostPicture src={event.host.picture || placeholderImage} />
                </HostedContainer>
                <p style={{ paddingTop: 20 }}>{event.host.host_info}</p>
              </EventSection>
            )}
            {event.sponsors &&
              event.sponsors.map(s => (
                <EventSection>
                  <h3>Sponsored by</h3>
                  <SponsorPicture
                    src={(theme.isDarkMode && s.imageDarkMode) || s.image}
                  />
                </EventSection>
              ))}
          </Content>
        )}
      </ContentContainer>
      {dayjs(event.endDate).isAfter(dayjs()) && <EventCTA event={event} />}
    </Container>
  )
}

EventDetails.propTypes = { id: PropTypes.string }
EventDetails.defaultProps = { id: '' }

export default EventDetails
