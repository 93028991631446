/**
 * @file An overlay component that swipes up for authentication
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import AppLayout from '../../templates/AppLayout'
import AuthEntryPage from './AuthEntryPage'
import AuthCodePage from './AuthCodePage'

const Container = styled(motion.div)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.Secondary};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  text-align: center;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.Text};
`

const AuthOverlay = () => {
  const authPhoneNumber = useSelector(state => state.auth.authPhoneNumber)

  return (
    <AppLayout hideHeaderAndPadding>
      <Container
        initial={{ top: '100%' }}
        animate={{ top: 0 }}
        exit={{ top: '100%' }}
      >
        <AnimatePresence initial={false}>
          {authPhoneNumber ? <AuthCodePage /> : <AuthEntryPage />}
        </AnimatePresence>
      </Container>
    </AppLayout>
  )
}

export default AuthOverlay
