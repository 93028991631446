/**
 * @file Google service to help with google related requests
 * @author Alwyn Tan
 */

import { Loader } from '@googlemaps/js-api-loader'

const BASIC_FIELDS = [
  'address_component',
  'adr_address',
  'business_status',
  'formatted_address',
  'geometry',
  'icon',
  'name',
  'photos',
  'place_id',
  'plus_code',
  'type',
  'url',
  'utc_offset_minutes',
  'vicinity',
]

const GoogleService = (() => {
  let google

  const initialize = async () => {
    google = await new Loader({
      apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    }).load()
  }

  return {
    initializeMap: async (mapElem, params) => {
      if (!google) await initialize()
      return new google.maps.Map(mapElem, params)
    },

    getAutocompleteSessionToken: async () => {
      if (!google) await initialize()
      return new google.maps.places.AutocompleteSessionToken()
    },

    getPlacePredictions: async params => {
      if (!google) await initialize()
      const autocompleteService = new google.maps.places.AutocompleteService()
      return autocompleteService.getPlacePredictions(params)
    },

    getPlaceDetails: async (map, params) => {
      if (!google) await initialize()
      const placesService = new google.maps.places.PlacesService(
        map || document.createElement('div')
      )

      return new Promise(resolve => {
        placesService.getDetails(
          { ...params, fields: BASIC_FIELDS },
          response => resolve(response)
        )
      })
    },

    createMarker: async params => {
      if (!google) await initialize()
      return new google.maps.Marker(params)
    },

    parseAddressComponents: addressComponents => {
      let streetAddress = ''
      let zip = ''
      let locality = ''
      let region = ''
      let country = ''

      for (const component of addressComponents) {
        const componentType = component.types[0]

        switch (componentType) {
          case 'street_number': {
            streetAddress = `${component.long_name} ${streetAddress}`
            break
          }
          case 'route': {
            streetAddress += component.short_name
            break
          }
          case 'postal_code': {
            zip = `${component.long_name}${zip}`
            break
          }
          case 'locality':
            locality = component.long_name
            break
          case 'administrative_area_level_1': {
            region = component.short_name
            break
          }
          case 'country':
            country = component.long_name
            break
          default:
            break
        }
      }

      return { streetAddress, zip, locality, region, country }
    },
  }
})()

export default GoogleService
