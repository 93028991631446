/**
 * @file Last page of the onboarding
 * @author Alwyn Tan
 */

import React from 'react'
import { useSelector } from 'react-redux'
import { PureInput } from '../../simple/Input'
import OnboardPage from './OnboardPage'

const OnboardNamePage = () => {
  const user = useSelector(state => state.auth.user)
  const tempUser = useSelector(state => state.user.onboardingState.user)

  return (
    <OnboardPage title="Welcome to Solo 🎉">
      {({ register }) => (
        <>
          <h3>What's your full name?</h3>
          <PureInput
            name="name"
            defaultValue={tempUser.name || user.name || ''}
            ref={register({ required: true })}
            placeholder="Full Name"
          />
        </>
      )}
    </OnboardPage>
  )
}

export default OnboardNamePage
