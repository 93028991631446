/**
 * @file Set profile info page
 * @author Alwyn Tan
 */

import React from 'react'
import { useSelector } from 'react-redux'
import { PureInput } from '../../simple/Input'
import OnboardPage from './OnboardPage'

const OnboardProfilePage = () => {
  const user = useSelector(state => state.auth.user)
  const tempUser = useSelector(state => state.user.onboardingState.user)

  return (
    <OnboardPage
      title="Finish Your Profile"
      subtitle="Stand out on Solo and help other people find you. (BTW, no fun fact is TMI 😉)"
    >
      {({ register }) => (
        <>
          <h3>A Fun Fact About You</h3>
          <PureInput
            name="funFact"
            placeholder="I know morse code"
            defaultValue={tempUser.funFact || user.funFact || ''}
            ref={register({ required: true })}
          />
          <h3>Your Work</h3>
          <PureInput
            name="profession"
            placeholder="Digital Marketer"
            defaultValue={tempUser.profession || user.profession || ''}
            ref={register({ required: true })}
          />
        </>
      )}
    </OnboardPage>
  )
}

export default OnboardProfilePage
