/**
 * @file Last page of the onboarding
 * @author Alwyn Tan
 */

import React from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { PureInput } from '../../simple/Input'
import OnboardPage from './OnboardPage'

const OnboardPrivateInfoPage = () => {
  const user = useSelector(state => state.auth.user)
  const tempUser = useSelector(state => state.user.onboardingState.user)

  return (
    <OnboardPage
      title="Extras"
      subtitle="Help us help you find better pod matches! This information won’t be part of your public profile"
    >
      {({ register }) => (
        <>
          <h3>Your Birthday</h3>
          <PureInput
            name="birthDate"
            defaultValue={
              tempUser.birthDate ||
              (user.birthDate && dayjs(user.birthDate).format('YYYY-MM-DD')) ||
              ''
            }
            ref={register({
              required: true,
              validate: value => dayjs(value).isBefore(dayjs()),
            })}
            type="date"
          />
          <h3>Your Zip Code</h3>
          <PureInput
            name="zipCode"
            placeholder="90019"
            type="number"
            defaultValue={tempUser.zipCode || user.zipCode || ''}
            ref={register({ required: true })}
          />
          <h3>Your Email</h3>
          <PureInput
            style={{ textTransform: 'lowercase' }}
            name="email"
            type="email"
            placeholder="info@soloclub.app"
            autocapitalize="none"
            defaultValue={tempUser.email || user.email || ''}
            ref={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'invalid email address',
              },
            })}
          />
        </>
      )}
    </OnboardPage>
  )
}

export default OnboardPrivateInfoPage
