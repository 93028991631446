/**
 * @file Invited to join an event (paid for)
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
import { navigate } from 'gatsby'
import useQueryString from '../../../hooks/useQueryString'
import { getEventDetails, registerEvent } from '../../../actions/events'
import { setAuthSuccessCallback } from '../../../actions/auth'
import placeholderImage from '../../../images/profilePlaceholder.png'
import EventTag from './EventDetails/EventTag'
import PlainCard from '../../compound/Cards/PlainCard'
import Button from '../../simple/Button'
import AddToCalendar from '../../simple/AddToCalendar'
import AuthOverlay from '../Auth/AuthOverlay'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Center = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const Image = styled.img`
  border-radius: 25px;
  height: 50px;
  width: 50px;
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0,0,0,0.15)'};
  margin-right: 15px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

const EventSummaryContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0,0,0,0.15)'};
  background-color: ${({ theme }) => theme.Secondary};
`

const EventSummary = styled.div`
  padding: 10px 20px 20px 20px;
`

const EventCoverImage = styled.img`
  width: 100%;
  height: 215px;
  background-color: ${({ theme }) => theme.Secondary};
  object-fit: cover;
  border-radius: 5px;
`

const EventInfoItem = styled.div`
  padding-top: 10px;
  display: flex;

  > span {
    font-size: 22px;
    padding-right: 10px;
  }
`

const LocationLink = styled.a`
  outline: none;
  text-decoration: none;
  color: inherit;
  overflow: hidden;

  > h4 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const EventInvite = ({ id }) => {
  const dispatch = useDispatch()
  const [showAuth, setShowAuth] = useState(false)
  const event = useSelector(state => state.events.details[id])
  const accessToken = useSelector(state => state.auth.accessToken)
  const currentUserID = useSelector(state => state.auth.user?.id)
  const { inv } = useQueryString()

  useEffect(() => {
    dispatch(getEventDetails({ id, inv }))
  }, [])

  useEffect(() => {
    if (currentUserID && event?.invitedSeatCode?.user?.id === currentUserID)
      navigate(`/app/events/${id}`, { replace: true })
  }, [currentUserID, event?.invitedSeatCode?.user?.id])

  if (!event || event.loading) return null

  const handleRedeemSeat = () => {
    const dispatchAction = () =>
      dispatch(registerEvent({ eventID: id, code: inv }))

    if (!accessToken) {
      setShowAuth(true)
      dispatch(setAuthSuccessCallback(dispatchAction))
    } else {
      setShowAuth(false)
      dispatchAction()
    }
  }

  return (
    <Container>
      <Center>
        {!event.invitedSeatCode || !event.invitedSeatCode === 'REDEEEMED' ? (
          <h2>Your invite is invalid or has expired!</h2>
        ) : (
          <>
            <h2>You're invited!</h2>
            <PlainCard style={{ width: '100%', marginTop: 20 }}>
              <Row>
                <Image
                  src={event.invitedSeatCode.user.picture || placeholderImage}
                />
                <div>
                  <p>{event.invitedSeatCode.user.name} bought you a seat to</p>
                  <h3>{event.title}</h3>
                </div>
              </Row>
              <ButtonContainer>
                <Button size="small" onClick={handleRedeemSeat}>
                  Redeem Your Seat
                </Button>
              </ButtonContainer>
            </PlainCard>
          </>
        )}
        <EventSummaryContainer>
          <EventCoverImage src={event.picture} />
          <EventSummary>
            {event.type === 'online' && <EventTag tag="online" />}
            <h2>{event.title}</h2>
            {(event?.locationV2?.formatted || event.location) && (
              <EventInfoItem>
                <span className="icon-map-pin" style={{ fontSize: 22 }} />
                <LocationLink
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    event?.locationV2?.formatted || event.location
                  )}`}
                >
                  <h4>{event?.locationV2?.formatted || event.location}</h4>
                </LocationLink>
              </EventInfoItem>
            )}
            {event.startDate && (
              <EventInfoItem>
                <span className="icon-calendar" />
                <AddToCalendar
                  event={{
                    description: event.about,
                    location:
                      event.type === 'online'
                        ? event.link
                        : event.locationV2?.formatted || event.location,
                    startDate: event.startDate,
                    endDate: event.endDate,
                    title: event.title,
                  }}
                  labelText={dayjs(event.startDate).format('D MMMM YYYY')}
                />
              </EventInfoItem>
            )}
            {event.startDate && event.endDate && (
              <EventInfoItem>
                <span className="icon-clock" />
                <h4>
                  {dayjs(event.startDate).format('h:mma')} -{' '}
                  {dayjs(event.endDate).format('h:mma')}
                </h4>
              </EventInfoItem>
            )}
            {event.price && (
              <EventInfoItem>
                <span className="icon-tag" />
                <h4>{`$${event.price / 100}`}</h4>
              </EventInfoItem>
            )}
          </EventSummary>
        </EventSummaryContainer>
        <AnimatePresence>
          {showAuth && !accessToken && <AuthOverlay />}
        </AnimatePresence>
      </Center>
    </Container>
  )
}

EventInvite.propTypes = { id: PropTypes.string }
EventInvite.defaultProps = { id: '' }

export default EventInvite
