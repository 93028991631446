/**
 * @file Add to Calendar Component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { makeURLWithQueryParams } from '../../utils/url'

const Container = styled.div`
  cursor: pointer;
`

const AddToCalendar = ({
  event: { title, description, location, startDate, endDate },
  style,
  labelText,
}) => {
  const generateGCalLink = () => {
    const BASE_URL = 'https://www.google.com/calendar/render'

    const params = {
      action: 'TEMPLATE',
      text: title,
      dates: `${dayjs(startDate).format('YYYYMMDDTHHmmss')}/${dayjs(
        endDate
      ).format('YYYYMMDDTHHmmss')}`,
      details: description,
      location,
    }

    return makeURLWithQueryParams(BASE_URL, params)
  }

  const handleClick = () => {
    window.open(generateGCalLink())
  }

  return (
    <Container style={style} onClick={handleClick}>
      <h4>{labelText}</h4>
    </Container>
  )
}

AddToCalendar.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }).isRequired,
  labelText: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
}

AddToCalendar.defaultProps = {
  labelText: 'Add To Calendar',
  style: {},
}

export default AddToCalendar
