/**
 * @file
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import toast from 'react-hot-toast'
import placeholderImage from '../../../images/profilePlaceholder.png'
import { getSuperpod } from '../../../actions/pod'
import { findVerification } from '../../../actions/verification'
import { setAuthSuccessCallback } from '../../../actions/auth'
import useQueryString from '../../../hooks/useQueryString'
import PlainCard from '../../compound/Cards/PlainCard'
import Button from '../../simple/Button'
import AuthOverlay from '../Auth/AuthOverlay'
import PodVerification from './PodVerification'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;

  > * {
    width: 100%;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    object-fit: cover;
    height: 80px;
    width: 80px;
    border-radius: 40px;
    margin-bottom: 20px;
    box-shadow: ${({ theme }) =>
      theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0,0,0,0.15)'};
  }

  p {
    white-space: pre-wrap;
    opacity: 0.75;
    padding-bottom: 10px;
  }
`

const PodMatesTitle = styled.div`
  display: flex;
  justify-content: space-between;
`

const ViewAll = styled.h3`
  cursor: pointer;
  color: ${({ theme }) => theme.Accent};
`

const Section = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid
    ${({ theme }) => (theme.isDarkMode ? '#ffffff' : '#000000')}26;

  h3 {
    padding-bottom: 20px;
  }

  p {
    white-space: pre-wrap;
  }
`

const PodMatesImgContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  justify-content: space-between;
  row-gap: 20px;
`

const UserImg = styled.img`
  cursor: pointer;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  object-fit: cover;
`

const CTA = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;

  > *:not(:first-child) {
    margin-top: 10px;
  }
`

const PodInfo = ({ name }) => {
  const dispatch = useDispatch()
  const { verifying } = useQueryString()
  const [showPodVerification, setShowPodVerification] = useState(false)
  const [showAuth, setShowAuth] = useState(false)
  const accessToken = useSelector(state => state.auth.accessToken)
  const superpod = useSelector(
    state => state.pod.superpodDetails[state.pod.nameToSuperpod[name]]
  )
  const userInPod = useSelector(
    state => state.auth.user?.superpods?.filter(s => s.name === name).length > 0
  )
  const podVerification = useSelector(state =>
    Object.values(state.verification.details).find(
      v => v.value === name && v.type === 'superpod'
    )
  )

  const renderPodMates = () =>
    superpod.users?.map(curr => (
      <UserImg
        src={curr.picture}
        key={curr.id}
        onClick={() => navigate(`/app/user/${curr.id}`)}
      />
    ))

  useEffect(() => {
    dispatch(getSuperpod(name))
    dispatch(findVerification({ type: 'superpod', value: name }))
  }, [name])

  useEffect(() => {
    setShowPodVerification(!!verifying)
  }, [verifying])

  useEffect(() => {
    if (superpod?.notFound) navigate('/app/404')
  }, superpod?.notFound)

  const handleJoinPod = () => {
    if (!accessToken) {
      setShowAuth(true)
      dispatch(
        setAuthSuccessCallback(() => {
          navigate(`${window.location.pathname}?verifying=true`, {
            replace: true,
          })
        })
      )
    } else {
      navigate(`${window.location.pathname}?verifying=true`, { replace: true })
    }
  }

  const handleShare = () => {
    if (navigator?.share) {
      navigator.share({ url: window.location.href })
    } else {
      navigator.clipboard.writeText(window.location.href)
      toast.success('Link Copied!')
    }
  }

  const renderTitleButton = () => {
    if (userInPod) {
      return (
        <Button size="small" type="secondary" onClick={() => {}}>
          Joined
        </Button>
      )
    }

    if (podVerification?.status === 'ACTIVE') {
      return (
        <Button disabled size="small" type="primary" onClick={() => {}}>
          Pending
        </Button>
      )
    }

    return (
      <Button size="small" type="primary" onClick={handleJoinPod}>
        Join
      </Button>
    )
  }

  const renderBigJoinButton = () => {
    if (userInPod) return <></>
    if (podVerification?.status === 'ACTIVE') {
      return (
        <Button disabled onClick={() => {}}>
          Verification Pending
        </Button>
      )
    }

    return <Button onClick={handleJoinPod}>Join The Pod</Button>
  }

  if (!superpod) return null

  if (showAuth) return <AuthOverlay />

  if (showPodVerification)
    return (
      <PodVerification
        superpod={superpod}
        hideVerification={() =>
          navigate(window.location.pathname, { replace: true })
        }
      />
    )

  return (
    <PlainCard style={{ maxWidth: 400 }}>
      <Container>
        <Title>
          <img src={superpod.picture || placeholderImage} alt="superpod" />
          <h2>{superpod.displayName}</h2>
          <p>{superpod.description}</p>
          {renderTitleButton()}
        </Title>
        <Section>
          <PodMatesTitle>
            <h3>Pod Mates</h3>
            <ViewAll onClick={() => navigate(`/app/pod/${name}/members`)}>
              View All
            </ViewAll>
          </PodMatesTitle>
          <PodMatesImgContainer>{renderPodMates()}</PodMatesImgContainer>
        </Section>
        <CTA>
          {renderBigJoinButton()}
          <Button type="secondary" onClick={handleShare}>
            Share
          </Button>
        </CTA>
      </Container>
    </PlainCard>
  )
}

PodInfo.propTypes = { name: PropTypes.string }

PodInfo.defaultProps = { name: '' }

export default PodInfo
