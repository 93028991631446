/**
 * @file Projects a modal on screen (single prompt, and submit)
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import { motion } from 'framer-motion'

const Container = styled(motion.div)`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

const SlideUpContainer = styled(motion.div)`
  transition: transform 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  width: 380px;
  max-width: 90%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.Secondary};
  height: 90%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
`

const GlobalStyle = createGlobalStyle`
  html {
    overflow: hidden;
  }
`

const Modal = ({ children, style, onCancel }) => {
  return (
    <Container
      initial={{
        backdropFilter: 'blur(0px) brightness(100%)',
        WebkitBackdropFilter: 'blur(0px) brightness(100%)',
      }}
      animate={{
        backdropFilter: 'blur(3px) brightness(40%)',
        WebkitBackdropFilter: 'blur(3px) brightness(40%)',
      }}
      exit={{
        backdropFilter: 'blur(0px) brightness(100%)',
        WebkitBackdropFilter: 'blur(0px) brightness(100%)',
      }}
      transition={{ duration: 0.3 }}
      onClick={onCancel}
    >
      <SlideUpContainer
        initial={{ y: '100%' }}
        animate={{ y: 0 }}
        exit={{ y: '100%' }}
        transition={{ duration: 0.15 }}
      >
        <Content style={style} onClick={e => e.stopPropagation()}>
          <GlobalStyle />
          {children}
        </Content>
      </SlideUpContainer>
    </Container>
  )
}

Modal.propTypes = {
  onCancel: PropTypes.func,
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
}

Modal.defaultProps = {
  onCancel: () => {},
  style: {},
}

export default Modal
