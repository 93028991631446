/**
 * @file Login with the code sent to the number
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { motion } from 'framer-motion'
import { login, requestLogin } from '../../../actions/auth'
import { PureInput } from '../../simple/Input'
import Button from '../../simple/Button'

const Form = styled(motion.form)`
  display: flex;
  flex-direction: column;
  max-width: 315px;
  color: inherit;
  min-height: 100%;
  justify-content: center;
  padding-bottom: 10%;

  > * {
    margin-bottom: 10px;
  }
`

const Resend = styled.button`
  color: ${({ theme }) => theme.Accent};
  background-color: transparent;
  outline: none;
  border: 0;
  font-weight: 500;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;

  :disabled {
    opacity: 0.5;
    cursor: default;
  }
`

const CODE_LENGTH = 4

const AuthCodePage = () => {
  const dispatch = useDispatch()
  const { handleSubmit, register, formState } = useForm({
    mode: 'onChange',
  })
  const isLoading = useSelector(state => state.auth.isLoading)
  const accessToken = useSelector(state => state.auth.accessToken)
  const authPhoneNumber = useSelector(state => state.auth.authPhoneNumber)

  const onSubmit = data => {
    dispatch(login(data.code))
  }

  const handleResendCode = e => {
    e.preventDefault()
    dispatch(requestLogin(authPhoneNumber))
  }

  const handleInputChange = e => {
    if (e.target.value.length === CODE_LENGTH) {
      handleSubmit(onSubmit)()
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      initial={{ x: 500, opacity: 1 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 0, opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div style={{ marginBottom: 80 }}>
        <h2>Enter the code we just texted you</h2>
      </div>
      <h4 style={{ marginBottom: 10 }}>What’s the code?</h4>
      <PureInput
        onChange={handleInputChange}
        placeholder="Enter Code"
        name="code"
        type="Text"
        autoComplete="one-time-code"
        ref={register({ required: true, pattern: /^\d{4}$/ })}
        style={{ paddingRight: 15, textAlign: 'center' }}
        maxLength={CODE_LENGTH}
      />
      <Button disabled={!formState.isValid || isLoading || !!accessToken}>
        Continue
      </Button>
      <h5 style={{ marginTop: 10 }}>
        Didnt't receive a code?
        <Resend onClick={handleResendCode}>Resend Code</Resend>
      </h5>
    </Form>
  )
}

export default AuthCodePage
