/**
 * @file Onboarding for the user
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import { onboardUser } from '../../../actions/user'
import AppLayout from '../../templates/AppLayout'
import OnboardPicturePage from './OnboardPicturePage'
import OnboardProfilePage from './OnboardProfilePage'
import OnboardPrivateInfoPage from './OnboardPrivateInfoPage'
import OnboardNamePage from './OnboardNamePage'
import OnboardUsernamePage from './OnboardUsernamePage'

const Container = styled(motion.div)`
  align-self: center;
  justify-self: flex-start;
  width: 100%;
  max-width: 375px;
  flex-direction: column;
  padding: 60px 30px;
  position: relative;
  display: table;
  min-height: 100%;
  height: 100%;
`

const OnboardingOverlay = () => {
  const dispatch = useDispatch()
  const currentPage = useSelector(state => state.user.onboardingState.page)

  const Pages = [
    <OnboardNamePage />,
    <OnboardUsernamePage />,
    <OnboardPicturePage />,
    <OnboardProfilePage />,
    <OnboardPrivateInfoPage />,
  ]

  useEffect(() => {
    if (currentPage === Pages.length) {
      dispatch(onboardUser())
    }
  }, [currentPage])

  return (
    <AppLayout hideHeaderAndPadding>
      <Container animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
        {currentPage < Pages.length && Pages[currentPage]}
      </Container>
    </AppLayout>
  )
}

export default OnboardingOverlay
