/**
 * @file Header For the app
 * @author Alwyn Tan
 */

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link, navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import logo from '../../images/logo.png'
import placeholderImage from '../../images/profilePlaceholder.png'
import { logout } from '../../actions/auth'

const Container = styled.div`
  min-height: 75px;
  padding: 20px 30px;
  background-color: ${({ theme }) => theme.Primary};
  width: 100%;
  display: flex;
  justify-content: center;
`

const Content = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Icons = styled.div`
  display: flex;
  padding-top: 2px;

  > * {
    margin-left: 15px;
  }
`

const ProfileIconContainer = styled.span`
  cursor: pointer;
  position: relative;
`

const ProfileIcon = styled.img`
  object-fit: cover;
  height: 25px;
  width: 25px;
  border-radius: 12.5px;
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0,0,0,0.15)'};
`

const Logo = styled.img`
  height: 35px;
  object-fit: contain;
  filter: ${({ theme }) => (theme.isDarkMode ? 'invert()' : 'unset')};
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-size: 22px;
  display: flex;
  justify-content: center;
  position: relative;
`

const SignIn = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const ProfileDropdown = styled.div`
  background-color: ${({ theme }) => theme.Secondary};
  border-radius: 10px;
  position: absolute;
  top: 30px;
  right: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  min-width: 150px;
`

const DropdownSection = styled.div`
  padding: 8px 40px 8px 16px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.Tertiary};
  }
`

const NotificationBubble = styled.div`
  height: 12px;
  width: 12px;
  background-color: #e74c3c;
  border-radius: 6px;
  position: absolute;
  right: -3px;
  top: -3px;
`

const selectHasUnreadMessages = createSelector(
  state => state.chat.roomDetails,
  state => state.auth.user?.id,
  (roomDetails, currUserID) => {
    return Object.keys(roomDetails).some(
      key =>
        roomDetails[key].lastMessage &&
        !roomDetails[key].lastMessage?.seen?.some(
          seenUser => seenUser === currUserID
        )
    )
  }
)

const AppHeader = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const accessToken = useSelector(state => state.auth.accessToken)
  const hasUnreadMessages = useSelector(state => selectHasUnreadMessages(state))
  const [showProfileDropdown, setShowProfileDropdown] = useState()
  const profileRef = useRef(null)

  useEffect(() => {
    const handleClick = e => {
      if (profileRef.current && !profileRef.current.contains(e.target)) {
        setShowProfileDropdown(false)
      }
    }
    window.document.addEventListener('click', handleClick)

    return () => window.document.removeEventListener('click', handleClick)
  }, [])

  const handleDropdownNav = to => {
    if (window.location.pathname !== to) navigate(to)
  }

  return (
    <Container>
      <Content>
        <StyledLink to="/app">
          <Logo src={logo} />
        </StyledLink>
        {user && accessToken ? (
          <Icons>
            <StyledLink to="/app/events/upcoming">
              <span className="icon-calendar" />
            </StyledLink>
            <StyledLink to="/app/chat">
              {hasUnreadMessages && <NotificationBubble />}
              <span className="icon-send" />
            </StyledLink>
            <StyledLink to="/app/notifications">
              <span className="icon-bell" />
            </StyledLink>
            <ProfileIconContainer ref={profileRef}>
              <ProfileIcon
                src={user.picture || placeholderImage}
                onClick={() => {
                  setShowProfileDropdown(!showProfileDropdown)
                }}
              />
              {showProfileDropdown && (
                <ProfileDropdown>
                  <DropdownSection
                    onClick={() => handleDropdownNav(`/app/user/${user.id}`)}
                    style={{ borderRadius: '10px 10px 0 0', paddingTop: 12 }}
                  >
                    <h4>{user.name}</h4>
                    <h5 style={{ opacity: 0.5 }}>{user.phoneNumber}</h5>
                  </DropdownSection>
                  <DropdownSection
                    onClick={() => handleDropdownNav(`/app/user/${user.id}`)}
                  >
                    <h4>Profile</h4>
                  </DropdownSection>
                  <DropdownSection
                    onClick={() => handleDropdownNav('/app/settings')}
                  >
                    <h4>Settings</h4>
                  </DropdownSection>
                  <DropdownSection
                    onClick={() => dispatch(logout())}
                    style={{ borderRadius: '0 0 10px 10px', paddingBottom: 12 }}
                  >
                    <h4>Sign Out</h4>
                  </DropdownSection>
                </ProfileDropdown>
              )}
            </ProfileIconContainer>
          </Icons>
        ) : (
          <SignIn to="/app/auth">
            <h4>sign in</h4>
          </SignIn>
        )}
      </Content>
    </Container>
  )
}

export default AppHeader
