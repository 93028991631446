/**
 * @file Styled switch component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import ReactSwitch from 'react-switch'
import { withTheme } from 'styled-components'

const Switch = ({ onChange, checked, theme, 'aria-label': ariaLabel }) => {
  return (
    <ReactSwitch
      checked={checked}
      onChange={onChange}
      uncheckedIcon={false}
      checkedIcon={false}
      onColor={theme.Accent}
      offColor={theme.isDarkMode ? '#444648' : '#C6C6C8'}
      height={22}
      width={40}
      handleDiameter={18}
      aria-label={ariaLabel}
    />
  )
}

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  'aria-label': PropTypes.string.isRequired,
  theme: PropTypes.shape({
    Accent: PropTypes.string,
    isDarkMode: PropTypes.bool,
  }).isRequired,
}

export default withTheme(Switch)
