/**
 * @file A plain card component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  padding: 20px;
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0,0,0,0.15)'};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.Secondary};
  width: 100%;
  position: relative;
`

const PlainCard = ({ children, className, style, onClick }) => {
  return (
    <Container style={style} onClick={onClick} className={className}>
      {children}
    </Container>
  )
}

PlainCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
  onClick: PropTypes.func,
}

PlainCard.defaultProps = {
  className: '',
  style: {},
  onClick: () => {},
}

export default PlainCard
