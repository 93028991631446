/**
 * @file Loading page of onboarding
 * @author Alwyn Tan
 */

import React from 'react'
import { useSelector } from 'react-redux'
import { Controller } from 'react-hook-form'
import ImageInputV2 from '../../simple/ImageInputV2'
import OnboardPage from './OnboardPage'

const OnboardPicturePage = () => {
  const tempUser = useSelector(state => state.user.onboardingState.user)

  return (
    <OnboardPage
      title="Welcome to Solo"
      subtitle="Let people know you're not a robot 🤖"
      allowSkip
    >
      {({ control }) => (
        <>
          <h3 style={{ width: '100%', textAlign: 'center' }}>
            Upload a Profile Picture
          </h3>
          <Controller
            as={
              <ImageInputV2
                resizeWidth={600}
                resizeHeight={600}
                previewHeight={200}
                previewWidth={200}
              />
            }
            name="picture"
            control={control}
            defaultValue={tempUser.picture || ''}
            rules={{ required: true }}
          />
        </>
      )}
    </OnboardPage>
  )
}

export default OnboardPicturePage
