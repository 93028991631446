/**
 * @file Page template for each onboarding page
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateOnboardingPage,
  updateOnboardingUser,
} from '../../../actions/user'
import Button from '../../simple/Button'

const Form = styled.form`
  min-height: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
`

const BackButton = styled.span`
  font-size: 22px;
  position: absolute;
  top: 65px;
  left: 30px;
  cursor: pointer;
  transition: 0.25s ease;

  :hover {
    opacity: 0.6;
  }
`

const InputsContainer = styled.div`
  max-width: 315px;

  h3 {
    margin-bottom: 7px;

    :not(:first-child) {
      margin-top: 20px;
    }
  }
`

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`

const TitleText = styled.div`
  text-align: center;
  width: 100%;

  > h3 {
    margin-top: 5px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;

  > * {
    width: 100%;
  }
`

const Skip = styled.h3`
  color: ${({ theme }) => theme.Accent};
  padding-top: 20px;
  width: 100%;
  text-align: center;
  cursor: pointer;
`

const OnboardPage = ({
  title,
  subtitle,
  children,
  buttonText,
  allowSkip,
  customSubmitCallback,
}) => {
  const dispatch = useDispatch()
  const formMethods = useForm({
    mode: 'onChange',
  })
  const currentPage = useSelector(state => state.user.onboardingState.page)
  const loading = useSelector(state => state.user.loading)

  const onSubmit = data => {
    if (customSubmitCallback) customSubmitCallback(data)
    else {
      dispatch(updateOnboardingUser(data))
      dispatch(updateOnboardingPage(1)) // next page
    }
  }

  const handleBackClick = () => {
    dispatch(updateOnboardingPage(-1))
  }

  const handleSkip = () => {
    dispatch(updateOnboardingPage(1))
  }

  return (
    <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
      {currentPage > 0 && (
        <BackButton className="icon-chevron-left" onClick={handleBackClick} />
      )}
      <TitleBar>
        <TitleText>
          <h2>{title}</h2>
          {subtitle && <h3>{subtitle}</h3>}
        </TitleText>
      </TitleBar>
      <InputsContainer>{children(formMethods)}</InputsContainer>
      <div style={{ height: 50 }} />
      <ButtonContainer>
        <Button disabled={!formMethods.formState.isValid || loading}>
          {buttonText}
        </Button>
        {allowSkip && <Skip onClick={handleSkip}>Skip</Skip>}
      </ButtonContainer>
    </Form>
  )
}

OnboardPage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  allowSkip: PropTypes.bool,
  children: PropTypes.func.isRequired,
  customSubmitCallback: PropTypes.func,
}

OnboardPage.defaultProps = {
  title: '',
  subtitle: '',
  buttonText: 'Next',
  allowSkip: false,
  customSubmitCallback: null,
}

export default OnboardPage
