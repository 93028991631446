/**
 * @file User Profile Page
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useSelector } from 'react-redux'

const Index = () => {
  const user = useSelector(state => state.auth.user)

  useEffect(() => {
    if (user.id) navigate(`/app/user/${user.id}`, { replace: true })
  }, [user.id])

  return <></>
}

export default Index
