/**
 * @file Page that handles Email Verification Status
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'
import useQueryString from '../../../hooks/useQueryString'
import { verifyEmail } from '../../../actions/verification'
import PlainCard from '../../compound/Cards/PlainCard'
import Footer from '../../simple/Footer'

const Container = styled.div`
  text-align: center;

  h2 {
    padding-bottom: 20px;
    color: ${({ theme, type }) =>
      type === 'success' ? theme.Success : theme.Accent};
  }
`

const EmailVerification = () => {
  const query = useQueryString()
  const dispatch = useDispatch()
  const emailVerificationSuccessful = useSelector(
    state => state.verification.emailVerificationSuccessful
  )

  useEffect(() => {
    if (query.v && query.t) {
      dispatch(verifyEmail({ v: query.v, t: query.t }))
      navigate('/app/verification/email', { replace: true })
    }
  }, [query])

  if (emailVerificationSuccessful === undefined) return null

  return (
    <>
      <PlainCard style={{ marginTop: 40, padding: '40px 20px', maxWidth: 400 }}>
        {emailVerificationSuccessful ? (
          <Container type="success">
            <h2>Email Verified! 🎉</h2>
            <p>
              You’re good to go. Please close this tab and head back to the
              previous page.
            </p>
          </Container>
        ) : (
          <Container type="failure">
            <h2>Your Link Expired 😵</h2>
            <p>
              Is your email already verified? If you’re still having issues,
              please email us at{' '}
              <a href="mailto:info@soloclub.app">info@soloclub.app</a>
            </p>
          </Container>
        )}
      </PlainCard>
      <Footer />
    </>
  )
}

export default EmailVerification
