/**
 * @file Renders the additional info modal
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { updateEventAdditionalInfo } from '../../../actions/events'
import TextArea from '../../simple/TextArea'
import Button from '../../simple/Button'
import Modal from '../../simple/Modal'

const Container = styled.form`
  text-align: center;
  width: 100%;
`

const AdditionalInfoModal = ({ eventID }) => {
  const dispatch = useDispatch()
  const event = useSelector(state => state.events.details[eventID])
  const [additionalInfo, setAdditionalInfo] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(updateEventAdditionalInfo({ additionalInfo, eventID }))
  }

  const handleInputChange = e => {
    setAdditionalInfo(e.target.value)
  }

  return (
    <Modal>
      <Container onSubmit={handleSubmit}>
        <h3>{event.additionalInfoQuestion}</h3>
        <TextArea
          style={{ textAlign: 'center', margin: '15px 0' }}
          placeholder={event.additionalInfoPlaceholder || 'Type Something...'}
          onChange={handleInputChange}
          value={additionalInfo}
        />
        <Button size="small" disabled={!additionalInfo}>
          Submit
        </Button>
      </Container>
    </Modal>
  )
}

AdditionalInfoModal.propTypes = { eventID: PropTypes.string.isRequired }

export default AdditionalInfoModal
