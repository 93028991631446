/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Text = styled.h4`
  padding: 2px 20px;
  background-color: white;
  color: black;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 20px;
  display: block;
  width: fit-content;
`

const EventTag = ({ tag }) => {
  return <Text>{tag}</Text>
}

EventTag.propTypes = {
  tag: PropTypes.string.isRequired,
}

export default EventTag
