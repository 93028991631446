/**
 * @file Input for chat messages
 * @author Alwyn Tan
 */

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Form = styled.form`
  position: relative;
  padding: 20px;
`

const Input = styled.input`
  outline: none;
  border: none;
  border-radius: 50px;
  width: 100%;
  font-weight: 500;
  font-size: 0.933em;
  background-color: ${({ theme }) => theme.Tertiary};
  height: 35px;
  min-height: 35px;
  padding-left: 15px;
  padding-right: 40px;
  color: ${({ theme }) => theme.Text};
`

const SendButton = styled.div`
  position: absolute;
  height: 28px;
  width: 28px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.Accent};
  top: calc(50% - 14.5px);
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s ease;
  cursor: pointer;
  color: white;

  @media (hover: hover) {
    :hover {
      opacity: 0.75;
    }
  }
`

const ChatMessageInput = ({ onSubmit }) => {
  const inputRef = useRef(null)

  const handleSubmit = e => {
    e.preventDefault()
    const message = inputRef.current.value.trim()
    if (message) onSubmit(message)
    inputRef.current.value = ''
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Input placeholder="Message" ref={inputRef} />
      <SendButton onClick={handleSubmit}>
        <span className="icon-arrow-up2" />
      </SendButton>
    </Form>
  )
}

ChatMessageInput.propTypes = {
  onSubmit: PropTypes.func,
}

ChatMessageInput.defaultProps = {
  onSubmit: () => {},
}

export default ChatMessageInput
