/**
 * @file Input component
 * @author Alwyn Tan
 */

import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'

const TextArea = styled(TextareaAutosize)`
  outline: none;
  border-radius: 10px;
  width: 315px;
  font-weight: 500;
  font-size: 0.933em;
  background-color: ${({ theme }) => theme.Tertiary};
  min-height: 40px;
  padding: 10px 15px;
  border-style: solid;
  border-color: #d63031;
  border-width: ${props => (props.error ? 2 : 0)};
  max-width: 100%;
  color: inherit;
  resize: none;
  appearance: none;

  ::placeholder {
    opacity: 0.6;
    font-weight: 500;
  }
`

export default TextArea
