/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { loadUpcomingEvents } from '../../../actions/events'
import Can from '../../../casl/Can'
import EventsNav from './EventsNav'
import FloatingActionButton from '../../simple/FloatingActionButton'
import InfiniteList from '../../compound/InfiniteList'
import EventCard from '../../compound/Cards/EventCard'
import LoadingCard from '../../compound/Cards/LoadingCard'

const Container = styled.div`
  height: 100%;
  width: 100%;
`

const UpcomingEvents = () => {
  const dispatch = useDispatch()
  const upcomingEvents = useSelector(state => state.events.upcoming)

  return (
    <Container>
      <InfiniteList
        items={upcomingEvents.ids}
        loadMoreItems={() => dispatch(loadUpcomingEvents())}
        canLoadMore={upcomingEvents.canLoadMore}
        loading={upcomingEvents.loading}
        stickyHeader={<EventsNav />}
        loadingComponent={<LoadingCard style={{ maxWidth: 400 }} />}
      >
        {item => <EventCard id={item} style={{ maxWidth: 400 }} />}
      </InfiniteList>
      <Can I="create" an="Event">
        <FloatingActionButton
          onClick={() => {
            navigate('/app/events/create')
          }}
        >
          <span className="icon-plus" style={{ fontSize: 25 }} />
        </FloatingActionButton>
      </Can>
    </Container>
  )
}

export default UpcomingEvents
