/**
 * @file Floating Button on the screen
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 50px;
  bottom: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
`

const CenterContainer = styled.div`
  width: 100%;
  max-width: 960px;
  position: relative;
`

const Button = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.Accent};
  color: white;
  position: absolute;
  bottom: 30px;
  right: 30px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: none;
  cursor: pointer;
`

// similar to app header. restricts the location of the button
const FloatingActionButton = ({ children, onClick }) => {
  return (
    <Container>
      <CenterContainer>
        <Button onClick={onClick}>{children}</Button>
      </CenterContainer>
    </Container>
  )
}

FloatingActionButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
}

FloatingActionButton.defaultProps = {
  onClick: () => {},
}

export default FloatingActionButton
