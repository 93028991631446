/**
 * @file Cards to depict individual info
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  padding-top: 5px;
`

const EventCard = styled.h3`
  text-align: center;
  padding: 10px !important;
  width: 100%;
  background-color: ${({ theme }) => theme.Tertiary};
  color: ${({ theme }) => theme.Text};
  border-radius: 10px;
  margin-bottom: 10px;
`

const EventCardList = ({ participants, type, additionalInfo }) => {
  const renderCards = () => {
    if (participants)
      return participants.map(
        participant =>
          participant[type] && (
            <EventCard key={`${participant.id}.${type}`}>
              {participant[type]}
            </EventCard>
          )
      )

    if (additionalInfo)
      return Object.keys(additionalInfo).map(key => (
        <EventCard key={key}>{additionalInfo[key]}</EventCard>
      ))

    return null
  }

  return <Container>{renderCards()}</Container>
}

EventCardList.propTypes = {
  type: PropTypes.string,
  /* eslint-disable-next-line react/forbid-prop-types */
  participants: PropTypes.arrayOf(PropTypes.object),
  /* eslint-disable-next-line react/forbid-prop-types */
  additionalInfo: PropTypes.object,
}

EventCardList.defaultProps = {
  participants: null,
  type: '',
  additionalInfo: null,
}

export default EventCardList
