/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import './layout.css'
import AppHeader from '../compound/AppHeader'

const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${({ theme }) => theme.Primary};
    color: ${({ theme }) => theme.Text};
    height: 100%;
  }

  a {
    color: ${({ theme }) => theme.Accent}
  }
  
  #gatsby-focus-wrapper > div {
    height: 100%;
  }
`

const FullScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Container = styled.div`
  padding: ${props => (props.hideHeaderAndPadding ? 0 : '0 20px')};
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
  height: 100%;
`

const Content = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const AppLayout = ({ className, children, hideHeaderAndPadding }) => {
  return (
    <FullScreenContainer>
      <GlobalStyle />
      {!hideHeaderAndPadding && <AppHeader />}
      <Container
        hideHeaderAndPadding={hideHeaderAndPadding}
        className={className}
      >
        <Content>{children}</Content>
      </Container>
    </FullScreenContainer>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  hideHeaderAndPadding: PropTypes.bool,
  className: PropTypes.string,
}

AppLayout.defaultProps = {
  hideHeaderAndPadding: false,
  className: '',
}

export default AppLayout
