/**
 * @file Floating Button on the screen
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { cancelEvent } from '../../../../actions/events'

const Container = styled.div`
  position: relative;
`

const EventIconButton = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  cursor: pointer;

  > span {
    font-size: 22px;
    text-align: center;
  }
`

const DropdownContainer = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.Tertiary};
  position: absolute;
  top: 50px;
  padding: 10px 20px;

  button {
    font-size: 100%;
    background-color: transparent;
    color: ${({ theme }) => theme.Text};
    border: none;
    outline: none;
  }

  h4 {
    cursor: pointer;
  }
`

const EventDropdownButton = ({ eventID }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const handleCancelEvent = () => {
    if (window.confirm('Are you sure?')) dispatch(cancelEvent(eventID))
  }

  return (
    <Container>
      <EventIconButton onClick={() => setOpen(!open)}>
        <span className="icon-edit" />
        <h5>Edit/Cancel</h5>
      </EventIconButton>
      {open && (
        <DropdownContainer>
          <button
            type="button"
            style={{ marginBottom: 10 }}
            onClick={handleCancelEvent}
          >
            <h5>Cancel</h5>
          </button>
          <button
            type="button"
            onClick={() => navigate(`/app/events/update/${eventID}`)}
          >
            <h5>Edit</h5>
          </button>
        </DropdownContainer>
      )}
    </Container>
  )
}

EventDropdownButton.propTypes = {
  eventID: PropTypes.string.isRequired,
}

export default EventDropdownButton
