/**
 * @file Image input that is compatible with react-hook-form
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Compressor from 'compressorjs'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  input {
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    cursor: pointer;
  }

  input[type='file']::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
`

const ImageContainer = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background-color: ${({ theme }) => theme.Secondary};
  border-radius: ${({ isCircular }) => (isCircular ? '100%' : '10px')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 15px 0;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    border-radius: ${({ isCircular }) => (isCircular ? '100%' : '10px')};
  }
`

const AddPhotoButton = styled.div`
  background-color: ${({ theme }) => theme.Tertiary};
  border-radius: 10px;
  position: absolute;
  padding: 10px 20px;
`

const ImageInputV2 = ({
  onChange,
  value,
  resizeWidth,
  resizeHeight,
  previewWidth,
  previewHeight,
  isCircular,
}) => {
  const [imagePreview, setImagePreview] = useState('')

  const handleImageUpload = e => {
    if (e.target.files.length > 0) {
      // always compress and resize
      new Compressor(e.target.files[0], {
        width: resizeWidth,
        height: resizeHeight,
        success(result) {
          onChange(result)
        },
        error(err) {
          console.error(err.message)
        },
      })
    }
  }

  useEffect(() => {
    let objectURL

    if (typeof value === 'object') {
      objectURL = URL.createObjectURL(value)
      setImagePreview(objectURL)
    } else if (typeof value === 'string' && value !== '') {
      objectURL = new URL(value)
      setImagePreview(objectURL)
    }

    return () => URL.revokeObjectURL(objectURL)
  }, [value])

  return (
    <Container>
      <ImageContainer
        width={previewWidth}
        height={previewHeight}
        isCircular={isCircular}
      >
        {imagePreview && <img src={imagePreview} alt="upload" />}
      </ImageContainer>
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      {!imagePreview && (
        <AddPhotoButton>
          <p>Add a Photo</p>
        </AddPhotoButton>
      )}
    </Container>
  )
}

ImageInputV2.propTypes = {
  resizeHeight: PropTypes.number.isRequired,
  resizeWidth: PropTypes.number.isRequired,
  previewWidth: PropTypes.number,
  previewHeight: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isCircular: PropTypes.bool,
}

ImageInputV2.defaultProps = {
  onChange: () => {},
  value: '',
  previewWidth: 315,
  previewHeight: 200,
  isCircular: false,
}

export default ImageInputV2
