/**
 * @file Set username page
 * @author Alwyn Tan
 */

import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import styled from 'styled-components'
import {
  setLoadingUser,
  setUpdateUsernameFailure,
  setUpdateUsernameSuccess,
  updateOnboardingPage,
  updateUsername,
  validateUsername,
} from '../../../actions/user'
import { PureInput } from '../../simple/Input'
import OnboardPage from './OnboardPage'

const ErrorText = styled.h4`
  color: #d63031;
  padding-top: 5px;
  white-space: pre-wrap;
  padding-left: 20px;
`
const OnboardUsernamePage = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const tempUser = useSelector(state => state.user.onboardingState.user)
  const availableUsernames = useSelector(state => state.user.availableUsernames)
  const updateUsernameSuccess = useSelector(
    state => state.user.updateUsernameSuccess
  )
  const updateUsernameFailure = useSelector(
    state => state.user.updateUsernameFailure
  )

  const setFormError = useRef(null)
  const currentUsername = useRef(null)

  const dispatchValidateUsername = useCallback(
    debounce(username => {
      dispatch(validateUsername(username))
    }, 250),
    []
  )

  const handleInputChange = (e, setError, errors) => {
    const username = e.target.value

    // dont dispatch if there is a pattern error
    if (!username || Object.values(errors).some(err => err.type === 'pattern'))
      return

    dispatch(setLoadingUser(true))
    setFormError.current = setError
    currentUsername.current = username
    dispatchValidateUsername(username)
  }

  const handleSubmit = data => {
    dispatch(updateUsername(data.username))
  }

  useEffect(() => {
    if (
      currentUsername.current &&
      !availableUsernames[currentUsername.current]
    ) {
      setFormError.current('username', { message: 'Username not available' })
    }
  }, [availableUsernames, currentUsername.current])

  useEffect(() => {
    if (updateUsernameSuccess) {
      dispatch(updateOnboardingPage(1))
      dispatch(setUpdateUsernameSuccess(false))
      dispatch(setUpdateUsernameFailure(''))
    }
  }, [updateUsernameSuccess])

  return (
    <OnboardPage
      title={`Welcome ${tempUser.name || user.name} 🎉`}
      customSubmitCallback={handleSubmit}
    >
      {({ register, setError, errors }) => (
        <>
          <h3>Choose a username</h3>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <h4 style={{ paddingRight: 5 }}>@</h4>
            <PureInput
              onChange={e => handleInputChange(e, setError, errors)}
              name="username"
              placeholder="soloclub"
              defaultValue={tempUser.username || user.username || ''}
              ref={register({
                required: true,
                pattern: {
                  value: /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
                  message: 'Please enter a valid username',
                },
              })}
            />
          </span>
          {updateUsernameFailure && (
            <ErrorText>{updateUsernameFailure}</ErrorText>
          )}
          {errors.username?.message && (
            <ErrorText>{errors.username.message}</ErrorText>
          )}
        </>
      )}
    </OnboardPage>
  )
}

export default OnboardUsernamePage
