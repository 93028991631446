/**
 * @file Pod Members Page
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import placeholderImage from '../../../images/profilePlaceholder.png'
import PlainCard from '../../compound/Cards/PlainCard'
import { getSuperpod } from '../../../actions/pod'
import InfiniteList from '../../compound/InfiniteList'
import { loadSuperpodUsers } from '../../../actions/user'
import PodMember from '../../compound/Pod/PodMember'

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;

  img {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin-right: 15px;
    object-fit: cover;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

const StyledCard = styled(PlainCard)`
  width: 100%;
  flex-grow: 1;
`

const PodMembers = ({ name }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const superpod = useSelector(
    state => state.pod.superpodDetails[state.pod.nameToSuperpod[name]]
  )
  const users = useSelector(state => state.user.superpods[name]) || {
    loading: false,
    canLoadMore: true,
    ids: [],
  }

  useEffect(() => {
    dispatch(getSuperpod(name))
  }, [])

  if (!superpod) return null

  return (
    <Container>
      <Header>
        <img
          src={superpod.picture || placeholderImage}
          alt={superpod.displayName}
        />
        <h2>{superpod.displayName}</h2>
      </Header>
      <StyledCard>
        <InfiniteList
          items={users.ids}
          loadMoreItems={() =>
            dispatch(loadSuperpodUsers({ superpodName: name }))
          }
          canLoadMore={users.canLoadMore}
          loading={users.loading}
          stickyHeader={
            <h3 style={{ backgroundColor: theme.Secondary }}>
              Members{' '}
              <span style={{ opacity: 0.5 }}>• {superpod.userCount}</span>
            </h3>
          }
        >
          {id => <PodMember id={id} />}
        </InfiniteList>
      </StyledCard>
    </Container>
  )
}

PodMembers.propTypes = {
  name: PropTypes.string,
}

PodMembers.defaultProps = {
  name: '',
}

export default PodMembers
