/**
 * @file City input component
 * @author Alwyn Tan
 */

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import debounce from 'lodash/debounce'
import GoogleService from '../../services/google'
import poweredByGoogleDark from '../../images/google/powered_by_google_on_non_white.png'
import poweredByGoogleLight from '../../images/google/powered_by_google_on_white.png'

const StyledSelect = styled(AsyncSelect)`
  display: inline-block;
  flex: 1;

  * {
    color: ${({ theme }) => theme.Text};
  }

  .react-select__single-value {
    color: ${({ theme }) => theme.Accent};
  }

  .react-select__placeholder {
    color: ${({ theme }) => theme.Accent};
  }

  .react-select__control {
    box-sizing: border-box;
    height: 100%;
    flex: 1;
    background-color: transparent;
    margin: 0;
    border: 0;
    box-shadow: none;
  }

  .react-select__value-container {
    height: 100%;
    padding: 0;
  }

  .react-select__indicators {
    display: none;
  }

  .react-select__input {
    height: 100%;
    color: ${({ theme }) => theme.Accent};
    font-weight: inherit;
    font-family: inherit;

    > input {
      margin-bottom: 0;
      font-weight: inherit;
      font-family: inherit;
    }
  }

  .react-select__menu {
    margin: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    background-color: ${({ theme }) => theme.Secondary};
    box-shadow: ${({ theme }) =>
      theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0,0,0,0.15)'};
    font-size: 0.8em;
    color: ${({ theme }) => theme.Accent};

    .react-select__option--is-focused,
    .react-select__option:focus,
    .react-select__option:hover,
    .react-select__option:active {
      background-color: ${({ theme }) => theme.Tertiary}80;
      color: ${({ theme }) => theme.Accent};
    }

    .react-select__option--is-selected {
      background-color: ${({ theme }) => theme.Tertiary};
      color: ${({ theme }) => theme.Accent};
    }
  }

  .react-select__menu-notice {
    text-align: left;
  }
`

const MenuFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px 10px 10px 10px;
`

const defaultOptions = [{ value: 'Anywhere', label: 'Anywhere' }]

const MenuList = props => {
  const { children, selectProps } = props

  const {
    MenuListHeader = null,
    MenuListFooter = null,
  } = selectProps?.components

  return (
    <components.MenuList {...props}>
      {MenuListHeader}
      {children}
      {MenuListFooter}
    </components.MenuList>
  )
}

const CityInput = ({ value, onChange }) => {
  const theme = useTheme()

  const debouncedDispatch = useCallback(
    debounce(async (input, callback) => {
      const response = await GoogleService.getPlacePredictions({
        types: ['(cities)'],
        input,
      })

      const options = response.predictions.map(({ terms, description }) => ({
        value: terms[0].value,
        label: description,
      }))

      callback(options)
    }, 500),
    []
  )

  const loadOptions = (inputValue, callback) => {
    debouncedDispatch(inputValue, callback)
  }

  return (
    <StyledSelect
      cacheOptions
      classNamePrefix="react-select"
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      onChange={onChange}
      value={value ? { value, label: value.value } : ''}
      components={{
        MenuList,
        MenuListFooter: (
          <MenuFooter>
            <img
              src={
                theme.isDarkMode ? poweredByGoogleDark : poweredByGoogleLight
              }
              alt="Powered by Google"
            />
          </MenuFooter>
        ),
      }}
    />
  )
}

MenuList.propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

CityInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

CityInput.defaultProps = {
  value: null,
}

export default CityInput
