/**
 * @file Profile cards to show user info
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.Secondary};
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0, 0, 0, 0.15)'};
  margin-bottom: 10px;
  text-align: left;
`

const ProfileCard = ({ title, content }) => {
  if (!content) return null

  return (
    <Container>
      <h3>{title}</h3>
      <p>{content}</p>
    </Container>
  )
}

ProfileCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

ProfileCard.defaultProps = {
  content: '',
}

export default ProfileCard
