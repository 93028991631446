/**
 * @file Chat
 * @author Alwyn Tan
 */

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { loadChatRooms } from '../../../actions/chat'
import ChatRoom from '../../compound/Chat/ChatRoom'
import ChatMessageView from '../../compound/Chat/ChatMessageView'
import AppLayout from '../../templates/AppLayout'
import SEO from '../../simple/SEO'
import InfiniteList from '../../compound/InfiniteList'

const FlexContainer = styled.div`
  flex-grow: 1;
  display: flex;
  overflow: scroll;
  padding: 20px 5px 20px 0px;
`

const ChatRoomsContainer = styled.div`
  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  width: 335px;
  min-width: 300px;
  margin-right: 15px;
`

const Chat = ({ roomID }) => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const showChatRooms = !isMobile || !roomID
  const showChatMessageView = !isMobile || roomID
  const showChatsTitle = !isMobile || (isMobile && !roomID)
  const chatRooms = useSelector(state => state.chat.rooms)

  return (
    <AppLayout hideHeaderAndPadding={Boolean(isMobile && roomID)}>
      <SEO title="Chat" description="Chat" />
      {showChatsTitle && <h2>Chats</h2>}
      <FlexContainer>
        {showChatRooms && chatRooms && (
          <ChatRoomsContainer>
            <InfiniteList
              items={chatRooms.ids}
              loadMoreItems={() => dispatch(loadChatRooms())}
              canLoadMore={chatRooms.canLoadMore}
              loading={chatRooms.loading}
            >
              {chatRoomID => (
                <ChatRoom id={chatRoomID} isActive={chatRoomID === roomID} />
              )}
            </InfiniteList>
          </ChatRoomsContainer>
        )}
        {showChatMessageView && <ChatMessageView roomID={roomID} />}
      </FlexContainer>
    </AppLayout>
  )
}

Chat.propTypes = {
  roomID: PropTypes.string,
}

Chat.defaultProps = {
  roomID: '',
}

export default Chat
