/**
 * @file A infinite list with dynamic sized components that uses react-virtualize to virtualize the list, and renders the dynamic elements "just-in-time", with sticky support
 * @author Alwyn Tan
 */

import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

const StickyListContext = createContext()
StickyListContext.displayName = 'StickyListContext'

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: ${({ theme }) => theme.Primary};
`

const InnerElementType = forwardRef(
  ({ children: innerChildren, style, ...rest }, ref) => {
    const stickyRef = useRef(null)
    const { setStickyHeight, stickyHeight, stickyHeader } = useContext(
      StickyListContext
    )

    useEffect(() => {
      if (stickyRef.current) setStickyHeight(stickyRef.current.offsetHeight)
    }, [stickyRef])

    return (
      <div
        ref={ref}
        style={{
          ...style,
          height: `${parseFloat(style.height) + stickyHeight}px`,
        }}
        {...rest}
      >
        <StickyContainer ref={stickyRef}>{stickyHeader}</StickyContainer>
        {innerChildren}
      </div>
    )
  }
)

const Row = ({ index, style, setRowHeight, stickyHeight, children }) => {
  const rowRef = useRef(null)
  useEffect(() => {
    if (rowRef.current) setRowHeight(index, rowRef.current.offsetHeight)
  }, [rowRef])

  return (
    <div
      style={{
        ...style,
        display: 'flex',
        alignItems: 'flex-end',
        top: `${parseFloat(style.top) + stickyHeight}px`,
      }}
    >
      <ItemWrapper ref={rowRef}>{children}</ItemWrapper>
    </div>
  )
}

const InfiniteList = ({
  items,
  loadMoreItems,
  loading,
  canLoadMore,
  children,
  marginBetween, // margin between each child
  stickyHeader,
  loadingComponent,
  endOfListComponent,
  noItemsComponent,
}) => {
  const listRef = useRef(null)
  const rowHeights = useRef({})
  const [stickyHeight, setStickyHeight] = useState(stickyHeader ? 50 : 0)

  const isItemLoaded = index => !canLoadMore || index < items.length

  // allow rows to load 1 extra row of either "loading", "End of List", or "No Items" special components
  const itemCount =
    canLoadMore ||
    (!canLoadMore && endOfListComponent) ||
    (items.length === 0 && noItemsComponent)
      ? items.length + 1
      : items.length

  const getRowHeight = index => rowHeights.current[index] + marginBetween || 100
  const setRowHeight = (index, size) => {
    listRef.current.resetAfterIndex(index)
    rowHeights.current = { ...rowHeights.current, [index]: size }
  }

  const handleLoadMoreItems = () => {
    if (!loading) loadMoreItems()
  }

  const renderRowComponent = index => {
    if (items.length === 0 && noItemsComponent) return noItemsComponent
    if (!isItemLoaded(index)) return loadingComponent
    if (!canLoadMore && endOfListComponent && index === itemCount - 1)
      return endOfListComponent
    return children(items[index])
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={handleLoadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <StickyListContext.Provider
              value={{
                setStickyHeight: value => setStickyHeight(value),
                stickyHeight,
                stickyHeader,
              }}
            >
              <VariableSizeList
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                height={height}
                width={width}
                itemSize={getRowHeight}
                ref={list => {
                  ref(list)
                  listRef.current = list
                }}
                innerElementType={InnerElementType}
              >
                {({ index, style }) => {
                  return (
                    <Row
                      index={index}
                      style={style}
                      setRowHeight={setRowHeight}
                      stickyHeight={stickyHeight}
                    >
                      {renderRowComponent(index)}
                    </Row>
                  )
                }}
              </VariableSizeList>
            </StickyListContext.Provider>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  )
}

InnerElementType.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
}

Row.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setRowHeight: PropTypes.func.isRequired,
  stickyHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
}

InfiniteList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadMoreItems: PropTypes.func.isRequired,
  canLoadMore: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  marginBetween: PropTypes.number,
  stickyHeader: PropTypes.node,
  loadingComponent: PropTypes.node,
  children: PropTypes.func.isRequired,
  endOfListComponent: PropTypes.node,
  noItemsComponent: PropTypes.node,
}

InfiniteList.defaultProps = {
  marginBetween: 10,
  stickyHeader: null,
  loadingComponent: <p>Loading</p>,
  endOfListComponent: null,
  noItemsComponent: null,
}

export default InfiniteList
