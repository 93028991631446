/**
 * @file View for all the chat messages
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import { navigate } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import ChatMessageInput from './ChatMessageInput'
import { CHAT_ROOM_TYPE } from '../../../constants'
import {
  sendChatMessage,
  seenChatMessage,
  getChatRoom,
} from '../../../actions/chat'
import placeholderImage from '../../../images/profilePlaceholder.png'
import ChatMessageList from './ChatMessageList'

const NoGlobalScroll = createGlobalStyle`
  html {
    overflow: hidden;
  }
`

const Container = styled.div`
  background-color: ${({ theme }) => theme.Secondary};
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0, 0, 0, 0.15)'};
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.Primary};
  }
`

const ChatHeader = styled.div`
  height: 70px;
  width: 100%;
  padding: 0 20px 0 14px;
  background-color: ${({ theme }) => theme.Secondary};
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid ${({ theme }) => theme.Primary};
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    background-color: ${({ theme }) => theme.Primary};
    border-bottom: 1px solid ${({ theme }) => theme.Secondary};
  }
`

const ChatHeaderInfo = styled.div`
  display: flex;
  align-items: center;

  > img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin-right: 10px;
    object-fit: cover;
  }
`

const BackButton = styled.span`
  font-size: 22px;
  padding-right: 5px;
`

const ChatMessageView = ({ roomID }) => {
  const dispatch = useDispatch()
  const chatRoom = useSelector(state => state.chat.roomDetails[roomID])
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const currentUserID = useSelector(state => state.auth.user?.id)

  useEffect(() => {
    dispatch(getChatRoom(roomID))
  }, [])

  // User has read the message
  const handleContainerFocus = () => {
    if (chatRoom?.lastMessage?.id)
      dispatch(seenChatMessage(chatRoom.lastMessage.id))
  }

  const handleBackClick = () => {
    navigate('/app/chat')
  }

  if (!chatRoom) return null

  const handleRoomNameClick = () => {
    if (
      chatRoom.participants.length === 2 &&
      chatRoom.type === CHAT_ROOM_TYPE.DEFAULT
    ) {
      const otherUserID = chatRoom.participants.filter(
        participant => participant.id !== currentUserID
      )[0].id
      navigate(`/app/user/${otherUserID}`)
    }
  }

  return (
    <Container
      style={{ borderRadius: isMobile && 0 }}
      onClick={handleContainerFocus}
    >
      {isMobile && <NoGlobalScroll />}
      <ChatHeader>
        {isMobile && (
          <BackButton className="icon-chevron-left" onClick={handleBackClick} />
        )}
        <ChatHeaderInfo onClick={handleRoomNameClick}>
          <img src={chatRoom.picture || placeholderImage} alt="profile" />
          <h4>{chatRoom.name}</h4>
        </ChatHeaderInfo>
      </ChatHeader>
      {/* infinitelist doesnt support inverted, so we have to manaully render... */}
      <ChatMessageList roomID={roomID} />
      <ChatMessageInput
        placeholder="Message"
        onSubmit={value => dispatch(sendChatMessage({ roomID, value }))}
      />
    </Container>
  )
}

ChatMessageView.propTypes = {
  roomID: PropTypes.string.isRequired, // can be null
}

export default ChatMessageView
