/**
 * @file User Profile Page
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { MessageCircle } from 'react-feather'
import placeholderImage from '../../../images/profilePlaceholder.png'
import { getUserDetails } from '../../../actions/user'
import { createChatRoom } from '../../../actions/chat'
import AppLayout from '../../templates/AppLayout'
import ProfileCard from '../../compound/Cards/ProfileCard'
import SEO from '../../simple/SEO'
import Button from '../../simple/Button'

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  height: 100%;
  padding: 50px 0;
  width: 100%;
  max-width: 400px;
  text-align: center;

  > h2 {
    padding-top: 10px;
  }
`

const ProfilePicture = styled.img`
  object-fit: cover;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0,0,0,0.15)'};
`

const ButtonContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`

const IconButton = styled.span`
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  border-radius: 15px;
  color: ${({ theme }) => theme.Accent};
  border: 2px solid ${({ theme }) => theme.Accent};
`

const userSelector = createSelector(
  (state, id) => state.auth.user?.id === id,
  (state, id) => state.user.details[id],
  state => state.auth.user,
  (isCurrentUser, otherUserDetails, currentUserDetails) =>
    isCurrentUser ? currentUserDetails : otherUserDetails
)

const User = ({ id }) => {
  const dispatch = useDispatch()

  const isLoggedIn = useSelector(state => state.auth.accessToken)
  const isCurrentUser = useSelector(state => state.auth.user?.id === id)
  const user = useSelector(state => userSelector(state, id))

  useEffect(() => {
    if (!isCurrentUser) dispatch(getUserDetails({ id }))
  }, [dispatch, user?.id, id])

  useEffect(() => {
    if (user?.notExist) {
      navigate('/app/404')
    }
  }, [user])

  if (!user)
    return (
      <AppLayout>
        <></>
      </AppLayout>
    )

  const handleSendMessageClick = () => dispatch(createChatRoom(id))

  const handleEditProfileClick = () => navigate('/app/profile/edit')

  const renderProfileButton = () => {
    if (!isLoggedIn) return null

    if (isCurrentUser) {
      return (
        <Button size="small" onClick={handleEditProfileClick}>
          Edit Profile
        </Button>
      )
    }

    return (
      <IconButton onClick={handleSendMessageClick}>
        <MessageCircle size={22} />
      </IconButton>
    )
  }

  const renderQuestions = () =>
    Object.keys(user.questions || {}).map(key => (
      <ProfileCard key={key} title={key} content={user.questions[key]} />
    ))

  return (
    <AppLayout>
      <SEO title={user.name} image={user.picture} />
      <CenterContainer>
        <Container>
          <ProfilePicture src={user.picture || placeholderImage} />
          <h2>{user.name}</h2>
          {user.bio && (
            <p style={{ paddingTop: 5 }}>
              <i>{user.bio}</i>
            </p>
          )}
          <ButtonContainer>{renderProfileButton()}</ButtonContainer>
          <ProfileCard title="I work in" content={user.profession} />
          <ProfileCard title="Did you know" content={user.funFact} />
          {renderQuestions()}
        </Container>
      </CenterContainer>
    </AppLayout>
  )
}

User.propTypes = { id: PropTypes.string }

User.defaultProps = { id: '' }

export default User
