/**
 * @file Explore Events page
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Controller, useForm } from 'react-hook-form'
import AppLayout from '../../templates/AppLayout'
import InfiniteList from '../../compound/InfiniteList'
import { exploreEvents, resetExploreEvents } from '../../../actions/events'
import ExploreEventCard from '../../compound/Cards/ExploreEventCard'
import CityInput from '../../compound/CityInput'

const Container = styled.div`
  justify-self: center;
  height: 100%;
  width: 100%;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    max-width: 400px;
    width: 100%;
    align-items: center;

    > h2 {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
`

const ExploreEvents = () => {
  const dispatch = useDispatch()
  const events = useSelector(state => state.events.explore)
  const [cityFilter, setCityFilter] = useState('Anywhere')

  const { control, handleSubmit } = useForm()

  const onSubmit = ({ city }) => {
    setCityFilter(city.value)
  }

  useEffect(() => {
    dispatch(resetExploreEvents())
  }, [cityFilter])

  return (
    <AppLayout>
      <Container>
        <InfiniteList
          key={`list-${cityFilter}`}
          items={events.ids}
          loadMoreItems={() => dispatch(exploreEvents({ city: cityFilter }))}
          canLoadMore={events.canLoadMore}
          loading={events.loading}
          noItemsComponent={
            <p style={{ textAlign: 'left', width: '100%', maxWidth: 400 }}>
              No events here yet :(
            </p>
          }
          stickyHeader={
            <Header>
              <div>
                <h2>
                  <span style={{ paddingRight: 5 }}>Go Solo</span>
                  <Controller
                    name="city"
                    key="city"
                    defaultValue={{ value: cityFilter, label: cityFilter }}
                    control={control}
                    render={({ onChange, value }) => (
                      <CityInput
                        value={value}
                        onChange={v => {
                          onChange(v)
                          handleSubmit(onSubmit)()
                        }}
                      />
                    )}
                  />
                </h2>
              </div>
            </Header>
          }
        >
          {id => <ExploreEventCard id={id} style={{ maxWidth: 400 }} />}
        </InfiniteList>
        )
      </Container>
    </AppLayout>
  )
}

export default ExploreEvents
