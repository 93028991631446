/* eslint-disable react/jsx-props-no-spreading */

/**
 * @file Protected routes, enforce user auth in these routes
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
import AuthOverlay from '../pages/Auth/AuthOverlay'

// Note: make sure access token is loaded before this component is used
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const accessToken = useSelector(state => state.auth.accessToken)

  return (
    <>
      <AnimatePresence>{!accessToken && <AuthOverlay />}</AnimatePresence>
      {accessToken && <Component {...rest} />}
    </>
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
}

export default ProtectedRoute
