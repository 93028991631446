/**
 * @file Progress Bars for form pages
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: ${props => (props.floating ? 'fixed' : 'unset')};
  width: ${props => (props.floating ? '300px' : 'unset')};
  bottom: ${props => (props.floating ? '100px' : 'unset')};
`

const ProgressIndicator = styled.div`
  height: 4px;
  width: 100%;
  margin-right: 5px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.Accent};

  :last-child {
    margin-right: 0;
  }
`

const FormProgressBar = ({ floating, segments, progress }) => {
  const renderProgress = () => {
    const progressBar = []

    for (let i = 0; i < segments; i++) {
      if (i <= progress) {
        progressBar.push(<ProgressIndicator key={`progressIndicator-${i}`} />)
      } else {
        progressBar.push(
          <ProgressIndicator
            key={`progressIndicator-${i}`}
            style={{ opacity: 0.5 }}
          />
        )
      }
    }

    return progressBar
  }

  return <Container floating={floating}>{renderProgress()}</Container>
}

FormProgressBar.propTypes = {
  floating: PropTypes.bool,
  segments: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
}

FormProgressBar.defaultProps = {
  floating: false,
}

export default FormProgressBar
