/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import { loadPastEvents } from '../../../actions/events'
import Can from '../../../casl/Can'
import FloatingActionButton from '../../simple/FloatingActionButton'
import InfiniteList from '../../compound/InfiniteList'
import EventCard from '../../compound/Cards/EventCard'
import LoadingCard from '../../compound/Cards/LoadingCard'
import EventsNav from './EventsNav'

const Container = styled.div`
  height: 100%;
  width: 100%;
`

const PastEvents = () => {
  const dispatch = useDispatch()
  const pastEvents = useSelector(state => state.events.past)

  return (
    <Container>
      <InfiniteList
        items={pastEvents.ids}
        loadMoreItems={() => dispatch(loadPastEvents())}
        canLoadMore={pastEvents.canLoadMore}
        loading={pastEvents.loading}
        stickyHeader={<EventsNav />}
        loadingComponent={<LoadingCard style={{ maxWidth: 400 }} />}
      >
        {item => <EventCard id={item} style={{ maxWidth: 400 }} />}
      </InfiniteList>
      <Can I="create" an="Event">
        <FloatingActionButton
          onClick={() => {
            navigate('/app/events/create')
          }}
        >
          <span className="icon-plus" style={{ fontSize: 25 }} />
        </FloatingActionButton>
      </Can>
    </Container>
  )
}

export default PastEvents
