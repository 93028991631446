/**
 * @file The login gate: request users to input their number
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import isMobilePhone from 'validator/lib/isMobilePhone'
import { Controller, useForm } from 'react-hook-form'
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-number-input'
import { Link } from 'gatsby'
import { requestLogin } from '../../../actions/auth'
import Button from '../../simple/Button'
import { PureInput } from '../../simple/Input'
import 'react-phone-number-input/style.css'

const Form = styled(motion.form)`
  display: flex;
  flex-direction: column;
  max-width: 315px;
  color: inherit;
  min-height: 100%;
  justify-content: center;
  padding-bottom: 10%;
  position: absolute;

  > * {
    margin-bottom: 10px;
  }

  .PhoneInput {
    input {
      padding-left: 55px;
    }
    position: relative;
  }

  .PhoneInputCountry {
    position: absolute;
    top: calc(50% - 7.5px);
    left: 15px;
  }

  --PhoneInputCountryFlag-borderColor: transparent;
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.Accent};
  cursor: pointer;
  text-decoration: none;
`

const AuthEntryPage = () => {
  const dispatch = useDispatch()
  const { handleSubmit, control, errors, formState } = useForm({
    mode: 'onChange',
  })
  const isLoading = useSelector(state => state.auth.isLoading)

  const onSubmit = data => dispatch(requestLogin(data.phoneNumber))

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      initial={{ x: -500, opacity: 1 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 0, opacity: 0 }}
    >
      <div style={{ marginBottom: 80 }}>
        <h2>Welcome to Solo</h2>
      </div>
      <h4 style={{ marginBottom: 10 }}>What's your number?</h4>
      <Controller
        as={PhoneInput}
        name="phoneNumber"
        control={control}
        rules={{
          required: true,
          validate: value => isMobilePhone(value) || 'Invalid Phone Number',
        }}
        defaultValue=""
        defaultCountry="US"
        inputComponent={PureInput}
        placeholder="Phone Number"
        type="tel"
        autoComplete="tel"
        style={{
          borderRadius: 10,
          border: errors.phoneNumber?.message && '2px solid red',
        }}
      />
      <Button disabled={!formState.isValid || isLoading}>Continue</Button>
      <h5 style={{ marginTop: 10 }}>
        By continuing, you acknowledge that you have read and agreed to our{' '}
        <StyledLink to="/terms">terms</StyledLink> and{' '}
        <StyledLink to="/privacy">privacy policy</StyledLink>
      </h5>
    </Form>
  )
}

export default AuthEntryPage
