/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../../simple/Button'
import Modal from '../../simple/Modal'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const YesNoContainer = styled.div`
  margin-top: 10px;

  > *:first-child {
    margin-right: 5px;
  }

  > *:last-child {
    margin-left: 5px;
  }
`

const BringAFriendModal = ({ onCancel, onYes, onNo }) => {
  return (
    <Modal style={{ height: 'auto' }} onCancel={onCancel}>
      <Container>
        <h3>Would you like to bring a friend?</h3>
        <YesNoContainer>
          <Button size="small" onClick={onYes}>
            Yes
          </Button>
          <Button size="small" type="secondary" onClick={onNo}>
            No
          </Button>
        </YesNoContainer>
      </Container>
    </Modal>
  )
}

BringAFriendModal.propTypes = {
  onCancel: PropTypes.func,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
}

BringAFriendModal.defaultProps = {
  onCancel: () => {},
  onYes: () => {},
  onNo: () => {},
}

export default BringAFriendModal
