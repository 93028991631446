/**
 * @file Explore Event Card
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'
import { Share } from 'react-feather'
import { toast } from 'react-hot-toast'
import PlainCard from './PlainCard'
import placeholderImage from '../../../images/profilePlaceholder.png'
import Button from '../../simple/Button'

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
`

const HostBar = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;

  > img {
    height: 30px;
    width: 30px;
    object-fit: cover;
    border-radius: 15px;
    margin-right: 10px;
  }
`

const CoverImg = styled.img`
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`

const EventInfoContainer = styled.div`
  padding: 15px;
`

const ButtonContainer = styled.div`
  padding-top: 10px;
  display: flex;
`

const ShareButton = styled.div`
  min-height: 33px;
  min-width: 33px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.Accent};
  margin-left: 10px;
  cursor: pointer;
`

const ExploreEventCard = ({ id, style }) => {
  const event = useSelector(state => state.events.details[id])

  const handleHostClick = () => {
    navigate(`/app/user/${event.host.id}`)
  }

  const handleCardClick = () => {
    navigate(`/app/events/${id}`)
  }

  const handleShareEvent = e => {
    e.stopPropagation()
    if (navigator?.share) {
      navigator.share({ url: `${window.location.origin}/app/events/${id}` })
    } else {
      navigator.clipboard.writeText(
        `${window.location.origin}/app/events/${id}`
      )
      toast.success('Link Copied!')
    }
  }

  return (
    <Container style={style}>
      <HostBar onClick={handleHostClick}>
        <img
          src={event.host.picture || placeholderImage}
          alt={event.host.name}
        />
        <p>
          <b>{event.host.name}</b> is hosting
        </p>
      </HostBar>
      <PlainCard
        style={{ padding: 0, cursor: 'pointer' }}
        onClick={handleCardClick}
      >
        <CoverImg src={event.picture || placeholderImage} alt={event.title} />
        <EventInfoContainer>
          <h5>{dayjs(event.startDate).format('ddd, MMM D, h:mma')}</h5>
          <h3 style={{ paddingTop: 5 }}>{event.title}</h3>
          <h4>{event.locationV2?.name || event.location}</h4>
          <h5 style={{ opacity: 0.5 }}>
            {event.price && `$${event.price / 100} • `}
            {event.seatsLeft} seat
            {event.seatsLeft === 1 ? ' ' : 's '}
            left
          </h5>
          <ButtonContainer>
            <Button size="small" style={{ width: '100%' }}>
              I'm In
            </Button>
            <ShareButton onClick={handleShareEvent}>
              <Share size={18} color="white" />
            </ShareButton>
          </ButtonContainer>
        </EventInfoContainer>
      </PlainCard>
    </Container>
  )
}

ExploreEventCard.propTypes = {
  id: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
}

ExploreEventCard.defaultProps = { style: {} }

export default ExploreEventCard
