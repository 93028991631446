/**
 * @file Edit Profile Page
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { ChevronLeft } from 'react-feather'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'
import AppLayout from '../../templates/AppLayout'
import { PureInput } from '../../simple/Input'
import TextArea from '../../simple/TextArea'
import ImageInputV2 from '../../simple/ImageInputV2'
import { updateUser } from '../../../actions/user'
import questions from '../../../constants/questions.json'

const Container = styled.form`
  align-self: center;
  width: 100%;
  max-width: 400px;
  padding: 0 20px 40px 20px;
  background-color: ${({ theme }) => theme.Secondary};
  box-shadow: ${({ theme }) =>
    theme.isDarkMode ? 'unset' : '0px 2px 8px rgba(0,0,0,0.15)'};
  border-radius: 10px;

  input {
    width: 100%;
  }

  @media only screen and (max-width: 500px) {
    max-width: unset;
    border-radius: 0px;
  }
`

const VerticalMargin = styled.div`
  min-height: 20px;

  @media only screen and (max-width: 500px) {
    min-height: 0;
  }
`

const Header = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const SaveButton = styled.input`
  background-color: transparent;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.Accent};
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
`

const InputContainer = styled.div`
  margin-bottom: 20px;

  > h3 {
    margin-bottom: 5px;
  }
`

const BackButton = styled.div`
  cursor: pointer;
`

const Divider = styled.div`
  background-color: ${({ theme }) => theme.Text}1A;
  height: 2px;
  width: 100%;
  margin: 30px 0;
`

const EditProfile = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const { control, handleSubmit, register, formState } = useForm({
    mode: 'onChange',
  })

  const { isDirty } = formState

  const onSubmit = data => {
    dispatch(updateUser(data))
  }

  const handleGoBack = () => {
    const message =
      'You have unsaved changes, are you sure you want to leave this page?'

    if (!isDirty || window.confirm(message)) navigate(`/app/user/${user.id}`)
  }

  const renderQuestionInputs = () => {
    const mapKeyToQuestionInput = key => (
      <InputContainer key={key}>
        <h3>{key}</h3>
        <PureInput
          name={`questions.${key}`}
          defaultValue={user.questions?.[key] || ''}
          ref={register()}
          placeholder="Answer"
        />
      </InputContainer>
    )

    const questionsInputs = Object.keys(user.questions || {}).map(
      mapKeyToQuestionInput
    )

    // for now we base the unanswered questions off the constant file we have
    // and render the unanswered available questions
    questionsInputs.push(
      ...Object.keys(questions)
        .filter(key => !(key in (user.questions || {})))
        .map(mapKeyToQuestionInput)
    )

    return questionsInputs
  }

  return (
    <AppLayout hideHeaderAndPadding>
      <VerticalMargin />
      <Container onSubmit={handleSubmit(onSubmit)}>
        <Header>
          <BackButton onClick={handleGoBack}>
            <ChevronLeft size={22} />
          </BackButton>
          <h3>
            <SaveButton type="submit" value="Save" />
          </h3>
        </Header>
        <h2 style={{ paddingBottom: 10 }}>Edit Personal Info</h2>
        <InputContainer>
          <h3>Profile Picture</h3>
          <Controller
            as={
              <ImageInputV2
                resizeWidth={600}
                resizeHeight={600}
                previewHeight={150}
                previewWidth={150}
                isCircular
              />
            }
            name="picture"
            control={control}
            defaultValue={user.picture || ''}
            rules={{ required: true }}
          />
        </InputContainer>
        <InputContainer>
          <h3>Name</h3>
          <PureInput
            name="name"
            defaultValue={user.name || ''}
            ref={register({ required: true })}
            placeholder="Alwyn Tan"
          />
        </InputContainer>
        <InputContainer>
          <h3>Bio</h3>
          <PureInput
            name="bio"
            defaultValue={user.bio || ''}
            ref={register()}
            placeholder="Go Solo"
          />
        </InputContainer>
        <InputContainer>
          <h3>Profession</h3>
          <PureInput
            name="profession"
            defaultValue={user.profession || ''}
            ref={register({ required: true })}
            placeholder="Profession"
          />
        </InputContainer>
        <InputContainer>
          <h3>Fun Fact</h3>
          <PureInput
            name="funFact"
            defaultValue={user.funFact || ''}
            ref={register({ required: true })}
            placeholder="Go Solo"
          />
        </InputContainer>
        <InputContainer>
          <h3>Birthday</h3>
          <PureInput
            name="birthDate"
            defaultValue={
              (user.birthDate && dayjs(user.birthDate).format('YYYY-MM-DD')) ||
              ''
            }
            ref={register({
              required: true,
              validate: value => dayjs(value).isBefore(dayjs()),
            })}
            type="date"
          />
        </InputContainer>
        <InputContainer>
          <h3>Host Description</h3>
          <TextArea
            name="host_info"
            defaultValue={user.host_info || ''}
            ref={register()}
            placeholder="Host Info"
            style={{ width: '100%' }}
          />
        </InputContainer>
        <Divider />
        <h2 style={{ paddingBottom: 10 }}>Edit Questions</h2>
        {renderQuestionInputs()}
      </Container>
      <VerticalMargin />
    </AppLayout>
  )
}

EditProfile.propTypes = {}

export default EditProfile
